<script>
import {subunits} from '@/assets/materials';
import LatexData from "@/components/LatexData.vue";
import axios from "axios";

export default {
  name: "Subunit",
  components: {LatexData},
  created() {
    this.getSubunit();
  },
  data() {
    return {
      unit: {},
    }
  },
  methods: {
    async getSubunit() {
      const unitName = this.$route.params.name;
      const response = await axios.get('/subunit/subCategory/', {
        params: {
          subCategory: unitName
        }
      });
      this.unit = response.data;
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleCategoryClick(event) {
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <Button class="button" text label="Powrót" @click="handleBack(unit)"></Button>
        <div class="title-container">
          <Button class="button" text :label="unit.title" @click="handleBack(unit)"></Button>
          ->
          <p class="unit-name">{{ unit.name }}</p>
        </div>
        <div class="latex-container">
          <div class="text-center">
            <h2>{{ unit.name }}</h2>
          </div>
          <LatexData v-if="unit.text" :equ="unit.text"></LatexData>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding: 1rem;
}

.title-container {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  font-size: 1rem;
}

.latex-container {
  margin-top: 3rem;
}

.button {
  padding: 0 0.5rem 0 0;
}

.pi-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

h2 {
  margin: 0;
}

.unit-name {
  padding-left: 0.5rem;
}

@media screen and (max-width: 640px) {
  .button {
    font-size: 0.8rem;
  }

  .unit-name {
    font-size: 0.8rem;
  }

  .container {
    padding: 0
  }

  h2 {
    font-size: 1.2rem;
  }
}
</style>