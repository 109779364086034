<script>
export default {
  name: 'PaymentCancel',
  created() {
    this.showToast('error', 'Wystąpił błąd podczas płatności', 'Spróbuj ponownie', 2000);
    this.$router.push('/profil');
  },
  methods: {
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}

</script>

<template>
  <Toast position="top-center" group="tc"/>
</template>

<style scoped>

</style>