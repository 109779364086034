<script>
import axios from "axios";

export default {
  name: "ReportModal",
  emits: ['send-message'],
  props: {
    taskNumber: String
  },
  data() {
    return {
      message: ''
    }
  },
  methods: {
    handleMessageChange(event) {
      this.message = event.target.value;
    },
    async handleSendReport() {
      let token = this.getCookie('lz_token');
      this.reportButtonDisabled = true;
      const sendEmailRes = await axios.post('/sendReport', {
        message: this.message,
        subject: this.taskNumber,
        token: token
      })
      if (sendEmailRes.data.status === 200) {
        this.$toast.add({severity: 'success', summary: 'Wiadomość wysłana', detail: '', group: 'tc', life: 2000});
        this.showModal = false;
        this.reportButtonDisabled = false;
        this.$emit('send-message');
      } else {
        this.$toast.add({
          severity: 'danger',
          summary: 'Wiadomość nie została wysłana',
          detail: sendEmailRes.data.message,
          group: 'tc',
          life: 2000
        });
        this.reportButtonDisabled = false;
      }
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="field">
      <InputText class="input" id="taskNumber" type="text" disabled :placeholder="taskNumber"/>
    </div>
    <div class="field">
    <span class="p-float-label">
      <Textarea class="input" v-model="message" rows="5" cols="35" autoResize @input="handleMessageChange"/>
       <label class="label" for="message">Opis</label>
    </span>
    </div>
    <div class="buttons-container">
        <div class="footer-button">
          <Button class="button" label="Wyślij zgłoszenie" icon="pi pi-send" @click="handleSendReport"></Button>
        </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  margin-top: 1rem;
}
.field {
  margin-bottom: 1.5em;
}
.input {
  width: 100%;
}
.button {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .input {
    font-size: 0.8rem;
  }
  .label {
    font-size: 0.7em;
  }
  .button {
    font-size: 0.8em;
  }
}
</style>