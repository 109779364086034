<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "WyrazeniaAlgb",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <LatexData class="small-font" equ="1) stosuje wzory skróconego mnożenia na: $\ (a+b)^2,\;(a-b)^2,\;a^2 - b^2$;"></LatexData>
      <p>2) dodaje, odejmuje i mnoży wielomiany jednej i wielu zmiennych;</p>
      <p>3) wyłącza poza nawias jednomian z sumy algebraicznej;</p>
      <p>4) mnoży i dzieli wyrażenia wymierne;</p>
      <p></p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      <p>Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:</p>
      <LatexData class="small-font" equ="1) dzieli wielomian jednej zmiennej$\ W(x)$ przez dwumian postaci$\ x−a$;"></LatexData>
      <p>2) rozkłada wielomiany na czynniki metodą wyłączania wspólnego czynnika przed nawias oraz metodą grupowania wyrazów;</p>
      <p>3) znajduje pierwiastki całkowite wielomianu o współczynnikach całkowitych;</p>
      <p>4) stosuje podstawowe własności trójkąta Pascala oraz następujące własności współczynnika dwumianowego (symbolu Newtona):</p>
      <LatexData class="small-font" equ="$\binom{n}{0}=1,$"></LatexData>
      <LatexData class="small-font" equ="$\binom{n}{1}=n,$"></LatexData>
      <LatexData class="small-font" equ="$\binom{n}{n-1}=n,$"></LatexData>
      <LatexData class="small-font" equ="$\binom{n}{k}=\binom{n}{n-k},$"></LatexData>
      <LatexData class="small-font" equ="$\binom{n}{k} + \binom{n}{k+1}=\binom{n+1}{k+1}$"></LatexData>
      <p>5) korzysta ze wzorów na:</p>
      <LatexData class="small-font" equ="$a^3+b^3,\;a^3-b^3,$"></LatexData>
      <LatexData class="small=font" equ="$a^n-b^n,\; (a+b)^n,\;(a-b)^n$"></LatexData>
      <p>6) dodaje i odejmuje wyrażenia wymierne, np.: </p>
      <LatexData class="small-font" equ="$\dfrac{1}{x+1}-\dfrac{1}x,$"></LatexData>
      <LatexData class="small-font" equ="$\dfrac1x + \dfrac1{x^2}+ \dfrac1{x^3},$"></LatexData>
      <LatexData class="small-font" equ="$\dfrac{x+1}{x+2} + \dfrac{x-1}{x+1}$"></LatexData>
    </div>
  </div>
</template>

<style scoped>

</style>