<script>
import axios from 'axios';
import Table from "@/components/Table.vue";
import TaskForm from "@/components/TaskForm.vue";
import TaskTable from "@/components/TaskTable.vue";
import UpdateTask from "@/components/UpdateTask.vue";
import InsertMaterial from "@/components/InsertMaterial.vue";
import UpdateMaterial from "@/components/UpdateMaterial.vue";
import Subscriptions from "@/components/Subscriptions.vue";
import FavoriteTasks from "@/components/FavoriteTasks.vue";
import UserData from "@/components/UserData.vue";
import TaskPreview from "@/components/TaskPreview.vue";
import AdminSQL from "@/components/AdminSQL.vue";
import {adminTabs} from "@/assets/utils";

export default {
  name: "Admin",
  components: {
    UserData, FavoriteTasks, Subscriptions,
    Table,
    TaskForm,
    TaskTable,
    UpdateTask,
    InsertMaterial,
    UpdateMaterial,
    TaskPreview,
    AdminSQL
  },
  created() {
    this.checkUser();
  },
  data() {
    return {
      allRecords: [],
      tabs: adminTabs,
      selectedTab: '',
      user: {}
    }
  },
  methods: {
    async checkUser() {
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
        if (this.user?.id !== '') {
          if (this.user.type !== 'admin') {
            this.$router.push('/');
          }
        }
      } else {
        this.$router.push('/');
      }
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <Toast/>
  <div class="card card-panel">
    <div class="card-body">
      <h2>Panel admina</h2>
      <TabView class="tab-view">
        <TabPanel header="Dodaj zadanie">
          <TaskForm></TaskForm>
        </TabPanel>
        <TabPanel header="Lista zadań">
          <Table></Table>
        </TabPanel>
        <TabPanel header="Tabela zadań">
          <TaskTable></TaskTable>
        </TabPanel>
        <TabPanel header="Podgląd zadania">
          <TaskPreview :customer-id="user.customerId"></TaskPreview>
        </TabPanel>
        <TabPanel header="SQL admina">
          <AdminSQL></AdminSQL>
        </TabPanel>
<!--        <TabPanel header="Dodaj materiał">
          <InsertMaterial></InsertMaterial>
        </TabPanel>
        <TabPanel header="Update materiał">
          <UpdateMaterial></UpdateMaterial>
        </TabPanel>-->
      </TabView>
      <div class="mobile-view">
        <Dropdown v-model="selectedTab" :options="tabs" optionLabel="label" placeholder="-Wybierz-" class="input"/>
        <div v-if="selectedTab.name === 'addTask'" class="mobile-container">
          <h3 class="header">Dodaj zadanie</h3>
          <hr/>
          <div class="component-container">
            <TaskForm></TaskForm>
          </div>
        </div>
        <div v-if="selectedTab.name === 'tasks'" class="mobile-container">
          <h3 class="header">Lista zadań</h3>
          <hr/>
          <div class="component-container">
            <Table></Table>
          </div>
        </div>
        <div v-if="selectedTab.name === 'tableTasks'" class="mobile-container">
          <h3 class="header">Tabela zadań</h3>
          <hr/>
          <div class="component-container">
            <TaskTable></TaskTable>
          </div>
        </div>
        <div v-if="selectedTab.name === 'adminSQL'" class="mobile-container">
          <h3 class="header">Admin SQL</h3>
          <hr/>
          <div class="component-container">
            <AdminSQL></AdminSQL>
          </div>
        </div>
<!--        <div v-if="selectedTab.name === 'addMaterial'" class="mobile-container">
          <h3 class="header">Dodaj materiał</h3>
          <hr/>
          <div class="component-container">
            <InsertMaterial></InsertMaterial>
          </div>
        </div>
        <div v-if="selectedTab.name === 'updateMaterial'" class="mobile-container">
          <h3 class="header">Edycja materiału</h3>
          <hr/>
          <div class="component-container">
            <UpdateMaterial></UpdateMaterial>
          </div>
        </div>-->
        <div v-if="selectedTab.name === 'taskPreview'" class="mobile-container">
          <h3 class="header">Pogląd zadania</h3>
          <hr/>
          <div class="component-container">
            <TaskPreview :customer-id="user.customerId"></TaskPreview>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tab-view {
  display: block;
}

.mobile-view {
  display: none;
}

.input {
  width: 100%;
}

.mobile-container {
  margin-top: 2rem;
}

.component-container {
  margin-top: 3rem;
}

@media screen and (max-width: 640px) {
  .card-panel {
    width: 100%;
  }

  .card-body {
    width: 100%;
    padding: 1rem;
  }

  .tab-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}
</style>