<script>
import axios from "axios";
export default {
  name: "TaskTable",
  data() {
    return {
      records: [],
      showTable: false,
      recordsSize: 0,
      basicLevelRecords: 0,
      advancedLevelRecords: 0
    }
  },
  methods: {
    async handleButtonClick() {
      this.records = [];
      const response = await axios.get('/tasks/allRecords');
      response.data.forEach(record => {
        this.records.push({
          amount: record['Count(*)'],
          unit: record.unit
        });
      });
      this.records.forEach(record => {
        this.recordsSize += record.amount;
      });
      const recordsByType = await axios.get('/tasks/recordsByType');
      if(recordsByType.data){
        recordsByType.data.forEach(record => {
          if(record.type === 'p') {
            this.basicLevelRecords = record['Count(*)'];
          } else {
            this.advancedLevelRecords = record['Count(*)'];
          }
        });
      }
      this.showTable = true;
    }
  }
}
</script>

<template>
  <Button class="refresh-button button-border" label="Odśwież" icon="pi pi-check" @click="handleButtonClick"></Button>
  <template v-if="showTable">
    <p>Podstawa: {{basicLevelRecords}}, Rozszerzenie: {{advancedLevelRecords}}</p>
    <p>Łącznie: {{recordsSize}}</p>
    <DataTable :value="records" tableStyle="width:100%" stripedRows>
      <Column field="unit" header="Unit"></Column>
      <Column field="amount" header="Amount"></Column>
    </DataTable>
  </template>
</template>

<style scoped>
.refresh-button {
  width: 10rem;
}
</style>