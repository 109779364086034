<script>
import axios from "axios";
import TaskAnswer from "@/components/TaskAnswer.vue";
import LatexData from "@/components/LatexData.vue";

export default {
  name: "TaskQuestion",
  components: {TaskAnswer,LatexData},
  created() {
    this.getQuestions();
  },
  props: {
    taskId: String,
    qUrl: String,
    answer: String
  },
  watch: {
    answer(){
      this.$refs.answer.processEqu(this.answer);
      this.correctAnswer = this.answer ? this.answer.substring(1,2) : '';
    },
    taskId() {
      this.questions = [];
      this.getQuestions();
    }
  },
  data() {
    return {
      questions: [],
      correctAnswer: ''
    }
  },
  methods: {
    async getQuestions() {
      if(Number.isInteger(this.taskId)){
        this.questions.push({
          id: 1,
          taskId: this.taskId
        })
      } else {
        const response = await axios.get('/tasks/getQuestions', {
          params: {
            taskId: this.taskId
          }
        });
        if (response.data.length > 0) {
          this.questions = response.data;
        } else {
          this.questions.push({
            id: 1,
            taskId: this.taskId
          });
          this.correctAnswer = this.answer ? this.answer.substring(1,2) : '';
        }
      }
    },
    handleAddMoreQuestion() {
      this.questions.push({
        id: this.questions.length +1,
        taskId: this.taskId
      })
    }
  }
}
</script>
<template>
  <p class="task-id font-bold">Zadanie: {{taskId}}</p>
  <div class="grid">
    <div class="col-6">
      <img class="img" :src="qUrl" alt="Pytanie">
    </div>
    <div class="col-6 font-bold">
      Odpowiedź: {{answer}}
      <LatexData ref="answer" :equ="answer"></LatexData>
    </div>
  </div>
  <Button class="mb-3 question-button button-border" label="Dodaj pytanie" severity="success" @click="handleAddMoreQuestion"></Button>
  <div class="grid">
    <div v-for="question in questions" class="col-12">
      <TaskAnswer :question-id="question.id" :task-id="taskId" :correct-answer-from-parent="correctAnswer"></TaskAnswer>
    </div>
  </div>

</template>

<style scoped>
img {
  width: 100%;
}
.task-id {
  margin: 0;
}
.question-button {
  width: 12rem;
}
</style>