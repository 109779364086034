<script>
export default {
  name: "Policy"
}
</script>

<template>
  <div class="card card-filter">
    <div class="card card-panel">
      <div class="card-body">
        <div class="main-div">
          <div class="div-center">
            <h2 class="title">Polityka prywatności losujzadanie.pl</h2>
            <h2 class="title center">Polityka prywatności</h2>
          </div>
          <div class="single-policy">
            Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych zbieranych przez
            stronę internetową [nazwa strony] zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
            dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
            sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
            ochronie danych) (dalej "RODO") oraz z obowiązującymi przepisami prawa polskiego.
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Administrator danych</h2>
            </div>
          </div>
          <div class="policy">
            <div class="single-policy">
              Administratorem danych osobowych jest PB CODE PRZEMYSŁAW BILIŃSKI, z siedzibą w Warszawie przy ul. Wierna
              23B/44, 03-890, NIP: 9512468521, REGON: 381159640. Dane kontaktowe Administratora to:
              <a class="link" href="mailto:kontakt@losujzadanie.pl">kontakt@losujzadanie.pl</a>.
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Cele przetwarzania danych</h2>
            </div>
          </div>
          <div class="policy">
            <div class="single-policy">
              Dane osobowe zbierane są w celu umożliwienia korzystania z usług świadczonych przez losujzadanie.pl, w tym
              m.in. rejestracji użytkowników, udostępniania treści, realizacji zamówień, obsługi płatności, komunikacji
              z
              użytkownikami oraz w celach marketingowych, zgodnie z wyrażoną zgodą.
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Rodzaje danych przetwarzanych</h2>
            </div>
            <div class="single-policy">
              Nasza strona internetowa zbiera i przetwarza następujące dane osobowe użytkowników:
              <ul>
                <li>Dane identyfikacyjne: imię, nazwisko, adres e-mail, numer telefonu,</li>
                <li>Dane logowania: nazwa użytkownika, hasło,</li>
                <li>Dane finansowe: dane karty kredytowej (w przypadku płatności elektronicznych),</li>
                <li>Dane techniczne: adres IP, informacje o przeglądarce internetowej, preferencje dotyczące języka.
                </li>
              </ul>
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Podstawa prawna przetwarzania danych</h2>
            </div>
            <div class="single-policy">
              Przetwarzanie danych osobowych odbywa się na podstawie:
              <ul>
                <li>zgody użytkownika,</li>
                <li>niezbędności przetwarzania do wykonania umowy lub podjęcia działań na żądanie użytkownika przed
                  zawarciem umowy,
                </li>
                <li>wypełnienia obowiązku prawnego ciążącego na Administratorze,</li>
                <li>prawnie uzasadnionego interesu Administratora.</li>
              </ul>
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Okres przechowywania danych</h2>
            </div>
            <div class="single-policy">
              Dane osobowe przechowywane są przez okres niezbędny do realizacji celów, dla których zostały zebrane, oraz
              zgodnie z obowiązującymi przepisami prawa.
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Prawa użytkowników</h2>
            </div>
            <div class="single-policy">
              Użytkownicy naszej strony mają prawo do:
              <ul>
                <li>dostępu do swoich danych osobowych,</li>
                <li>sprostowania swoich danych osobowych,</li>
                <li>usunięcia swoich danych osobowych,</li>
                <li>ograniczenia przetwarzania swoich danych osobowych,</li>
                <li>przenoszenia swoich danych osobowych,</li>
                <li>wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych.</li>
              </ul>
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Bezpieczeństwo danych</h2>
            </div>
            <div class="single-policy">
              Dbamy o bezpieczeństwo danych osobowych naszych użytkowników, stosując odpowiednie środki techniczne i
              organizacyjne zapewniające ochronę przed nieautoryzowanym dostępem, utratą lub uszkodzeniem danych.
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Kontakt</h2>
            </div>
            <div class="single-policy">
              W sprawach dotyczących ochrony danych osobowych oraz realizacji praw użytkowników można kontaktować się z
              nami pod adresem e-mail: <a class="link" href="mailto:kontakt@losujzadanie.pl">kontakt@losujzadanie.pl</a>.
            </div>
          </div>
          <div class="policy">
            <div class="div-center">
              <h2 class="policy-header center">Zmiany w Polityce Prywatności</h2>
            </div>
            <div class="single-policy">
              Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności w dowolnym momencie.
              Aktualna wersja Polityki Prywatności jest zawsze dostępna na naszej stronie internetowej.
            </div>
          </div>
          <div class="single-policy">
            Ostatnia aktualizacja: <span class="font-bold">07.04.2024</span>
          </div>
          <div class="single-policy">
            Data publikacji: <span class="font-bold">07.04.2024</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-filter {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
}

.div-center {
  text-align: center;
}

.single-policy {
  margin-bottom: 0.5em;
  line-height: 1.6;
}

.main-div {
  padding: 2em;
}

.policy {
  margin-bottom: 2em;
}

ul {
  margin-top: 0;
}

.link {
  color: #8B5CF6;
}

@media screen and (max-width: 640px) {
  .main-div {
    padding: 0.5rem;
  }

  .title {
    font-size: 1.2em;
  }

  .policy-header {
    font-size: 1.2em;
  }

  .single-policy {
    font-size: 0.8em;
  }
}

</style>