<script>
import Comment from "@/components/Comment.vue";
import CreateComment from "@/components/CreateComment.vue";
import axios from "axios";

export default {
  name: "CommentParent",
  components: {CreateComment, Comment},
  props: {
    taskId: String,
    customerId: String
  },
  created() {
    this.getComments();
  },
  data() {
    return {
      comments: []
    }
  },
  methods: {
    async getComments() {
      this.comments = [];
      const response = await axios.get('/tasks/getComments', {
        params: {
          taskId: this.taskId
        }
      });
      this.comments = response.data;
    },
    handleCommentCreated() {
      this.getComments();
    },
    handleDeleteComment() {
      this.getComments();
    }
  }
}
</script>

<template>
  <div>
    <div class="mb-4">
      <h3>Komentarze ({{ comments.length }}): </h3>
    </div>
    <div>
      <CreateComment :task-id="taskId" :customer-id="customerId" @create-comment="handleCommentCreated"></CreateComment>
      <template v-for="comment in comments" :key="comment.id">
        <Comment :comment="comment" :users-comment="comment.customerId === customerId"
                 @delete-comment="handleDeleteComment"></Comment>
      </template>
    </div>
  </div>
</template>

<style scoped>

</style>