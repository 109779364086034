<script>
import {computed, reactive} from "vue";
import {email, helpers, required} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import axios from "axios";

export default {
  name: "Contact",
  setup() {
    const state = reactive({
      name: '',
      email: '',
      topic: '',
      message: ''
    })
    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage('Pole Wymagane', required)
        },
        email: {
          required: helpers.withMessage('Pole Wymagane', required),
          email: helpers.withMessage('Błędny format', email)
        },
        topic: {
          required: helpers.withMessage('Pole Wymagane', required)
        },
        message: {
          required: helpers.withMessage('Pole Wymagane', required)
        }
      }
    })
    const v$ = useValidate(rules, state);
    return {
      state,
      v$
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.$toast.add({severity: 'error', summary: 'Wypełnij poprawnie wszystkie pola', detail: '', life: 2000});
        return;
      }
      const emailData = {
        name: this.state.name,
        email: this.state.email,
        topic: this.state.topic,
        message: this.state.message
      };

      const response = await axios.post('/sendContactEmail', {
        emailData: emailData
      });
      if (response.data.status === 200) {
        this.showToast('success', 'Email został wysłany', '', 2000);
        this.resetForm();
      } else {
        this.showToast('error', 'Błąd', response.data.message, 2000);
      }
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    },
    resetForm() {
      location.reload();
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <h2>Wyślij swoje zapytanie</h2>
        <div class="form">
          <div class="grid">
            <div class="col-container md:col-6 sm:col-12">
              <div class="field">
                  <span class="p-float-label">
                    <InputText id="state.name" v-model="state.name" type="text" class="input" required/>
                    <label for="state.name">Twoje imię i nazwisko</label>
                  </span>
                <div class="error mt-1" v-if="v$.name.$error">
                  {{ v$.name.$errors[0].$message }}
                </div>
              </div>
              <div class="field">
                  <span class="p-float-label">
                    <InputText id="state.email" v-model="state.email" type="email" class="input"/>
                    <label for="state.email">Email</label>
                  </span>
                <div class="error mt-1" v-if="v$.email.$error">
                  {{ v$.email.$errors[0].$message }}
                </div>
              </div>
              <div class="field">
                  <span class="p-float-label">
                    <InputText id="state.topic" v-model="state.topic" type="text" class="input"/>
                    <label for="state.topic">Temat</label>
                  </span>
                <div class="error mt-1" v-if="v$.topic.$error">
                  {{ v$.topic.$errors[0].$message }}
                </div>
              </div>
            </div>
            <div class="col-container md:col-6 sm:col-12">
              <div class="field">
                  <span class="p-float-label">
                    <Textarea class="textarea" v-model="state.message"></Textarea>
                    <label for="state.message">Wiadomość</label>
                  </span>
                <div class="error mt-1" v-if="v$.message.$error">
                  {{ v$.message.$errors[0].$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <Button class="button" label="Wyślij" icon="pi pi-send" @click="onSubmit"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.form {
  margin-top: 2rem;
}

.grid {
  margin: 0;
}

.field {
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 20rem;
}

.button {
  width: 25rem;
}

.error {
  color: #f13030;
  font-size: .8em;
}

@media screen and (max-width: 640px) {
  .col-container {
    width: 100%;
  }

  .textarea {
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
</style>