<script>
import { plans } from '@/assets/utils.js';
import PlanCard from "@/components/PlanCard.vue";
export default {
  name: "Plans",
  components: {PlanCard},
  emits: ['plan-selected'],
  props: {
    hideButtons: Boolean
  },
  data() {
    return {
      plans: plans,
    }
  },
  methods: {
    handlePlanSelected(event){
      this.$emit('plan-selected', event);
    }
  }
}
</script>

<template>
  <div class="custom-grid">
    <div v-for="plan in plans" class="single-plan">
      <PlanCard :plan="plan" @plan-selected="handlePlanSelected"
                :hide-buttons="hideButtons"></PlanCard>
    </div>
  </div>
</template>

<style scoped>
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.single-plan {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 640px) {
  .single-plan {
    width: 100%;
    margin-bottom: 2em;
  }
}
</style>