<script>
import axios from "axios";
import {computed, reactive} from "vue";
import {helpers, minLength, maxLength, required, sameAs} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  name: "ResetPassword",
  setup() {
    const state = reactive({
      password: '',
      confirmPassword: ''
    })
    const rules = computed(() => {
      return {
        password: {
          required: helpers.withMessage('Pole Wymagane', required),
          minLength: helpers.withMessage('Hasło musi zawierać co najmniej 6 znaków',minLength(6)),
          maxLength: helpers.withMessage('Hasło może zawierać maksymalnie 40 znaków',maxLength(40))
        },
        confirmPassword: {
          required: helpers.withMessage('Pole Wymagane', required),
          sameAsPassword: helpers.withMessage('Hasła muszą być takie same', sameAs(state.password)),
        },
      }
    })
    const v$ = useValidate(rules, state);
    return {
      state,
      v$
    }
  },
  created() {
    this.validToken();
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      showPasswordSection: false,
      email: '',
      token: ''
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.showToast('error','Wypełnij poprawnie wszystkie pola', '',2000);
        return;
      }
      try {
        let passwordData = {
          email: this.email,
          password: this.state.password,
          token: this.token
        };
        const response = await axios.post('/users/resetPassword', passwordData);
        if (response.data.status === 200) {
          this.showToast('success', 'Hasło zostało zmienione', '', 2000);
          this.$router.push('/');
        } else {
          this.showToast('error', 'Błąd',response.data.message, 2000);
        }
      } catch (error) {
        console.error('Error: ', error);
        this.showToast('error', 'Błąd',error, 2000);
      }
    },
    async validToken() {
      const url = this.$route.query;
      this.email = url.email;
      this.token = url.token;
      const response = await axios.post('/validToken', {
        email: url.email,
        token: url.token
      });
      this.showPasswordSection = response.data.status === 200;
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <div class="card card-panel">
    <Card style="width:80em">
      <template #title>Resetowanie hasła</template>
      <template #content>
        <div v-if="showPasswordSection" class="formgrid grid mt-2">
          <div class="field col">
            <form @submit="onSubmit" class="flex flex-column gap-2">
              <div class="field">
                <span class="p-float-label">
                  <InputText id="state.password" v-model="state.password" type="password" class="input"/>
                  <label class="label" for="state.password">Nowe hasło</label>
                </span>
                <div class="error mt-1" v-if="v$.password.$error">
                  {{ v$.password.$errors[0].$message}}
                </div>
              </div>
              <div class="field">
                <span class="p-float-label">
                  <InputText id="state.confirmPassword" v-model="state.confirmPassword" type="password" class="input"/>
                  <label class="label" for="state.confirmPassword">Powtórz nowe hasło</label>
                </span>
                <div class="error mt-1" v-if="v$.confirmPassword.$error">
                  {{ v$.confirmPassword.$errors[0].$message}}
                </div>
              </div>
            </form>
            <Button class="button" type="submit" label="Zapisz" icon="pi pi-check" @click="onSubmit"/>
          </div>
        </div>
        <div v-else>
          <h3>Link do resetowania hasła wygasł lub wystąpił błąd.</h3>
        </div>
      </template>
    </Card>
  </div>
</template>

<style scoped>
.card-panel {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
}
.input {
  width: 20em;
}
.error {
  color: #f13030;
  font-size: .8em;
}
.field {
  margin-bottom: 1em;
}
.button {
  width: 20em;
  margin-top: 1rem;
}
@media screen and (max-width: 640px) {
  .input {
    width:100%;
    font-size: 0.8rem;
  }
  .label {
    font-size: 0.7em;
  }
  .button {
    width: 100%;
    font-size: 0.8em;
  }
}

</style>