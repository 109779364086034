<script>
import LatexData from "@/components/LatexData.vue";
export default {
  name: "RownNrow",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <LatexData class="small-font" equ="1) przekształca równania i nierówności w sposób równoważny, w tym np. przekształca równoważnie równanie $\dfrac{5}{x+1} = \dfrac{x+3}{2x-1}$"></LatexData>
      <p>2) interpretuje równania i nierówności sprzeczne oraz tożsamościowe;</p>
      <p>3) rozwiązuje nierówności liniowe z jedną niewiadomą;</p>
      <p>4) rozwiazuje równania i nierówności kwadratowe;</p>
      <LatexData class="small-font" equ="5)rozwiązuje równania wielomianowe postaci$\ W(x)=0$ dla wielomianów doprowadzonych do postaci iloczynowej;"></LatexData>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <LatexData class="small-font" equ="1) rozwiązuje równania wielomianowe postaci$\ W(x)=0$ oraz nierówności wielomianowe typu:"></LatexData>
      <LatexData class="small-font" equ="$W(x)>0,\; W(x)≥0,$"></LatexData>
      <LatexData class="small-font" equ="$W(x)<0,\;W(x)≤0$"></LatexData>
      <p>dla wielomianów doprowadzonych do postaci iloczynowej lub takich, które dają się doprowadzić do postaci iloczynowej metodą wyłączania wspólnego czynnika przed nawias lub metodą grupowania</p>
      <p>2) rozwiązuje równania i nierówności wymierne, które dadzą się sprowadzić do równania lub nierówności liniowej lub kwadratowej;</p>
      <p>3) stosuje wzory Viete'a dla równań kwadratowych;</p>
      <p>4)rozwiązuje równania i nierówności z wartością bezwzględną;</p>
      <p>5) analizuje równania i nierówności liniowe z parametrami oraz równania i nierówności kwadratowe z parametrami, w szczególności wyznacza liczbę rozwiązań w zależności od parametrów, podaje warunki, przy których rozwiązania mają określone znaki bądź należą do określonego przedziału, wyznacza rozwiązania w zależności od parametrów;</p>
      <p>6) rozwiązuje równania wielomianowe, które dają się doprowadzić do równania kwadratowego, w szczególności równania dwukwadratowe;</p>
      <LatexData class="small-font" equ="7) rozwiązuje równania wymierne postaci$\dfrac{V(x)}{W(x)}=0$, gdzie wielomiany$\ V(x)$ i$\ W(x)$ są zapisane w postaci iloczynowej."></LatexData>
    </div>
  </div>
</template>

<style scoped>

</style>