<script>
export default {
    name: 'DataTableResponsive',
    emits: ['record-selected', 'record-removed'],
    props: {
      records: Array,
      columns: Array
    },
  methods: {
    handleRecordSelect(event) {
      this.$emit('record-selected', event);
    },
    handleRemove(event) {
      this.$emit('record-removed', event);
    }
  }
}
</script>
<template>
  <table>
    <thead>
    <tr>
      <template v-for="column of columns" :key="column.id">
        <th>{{column.label}}</th>
      </template>
    </tr>
    </thead>
    <tbody>
    <template v-for="record of records" :key="record.id">
      <tr>
        <td data-label="Matura">{{record.exam}}</td>
        <td data-label="Typ">{{record.type}}</td>
        <td data-label="Numer zadania">{{record.taskNumber}}</td>
        <td data-label="Dział">{{record.unit}}</td>
        <td class="button-row">
          <Button class="button" label="Otwórz" :value="record.id" @click="handleRecordSelect(record)"/>
        </td>
        <td class="button-row">
          <Button class="button" severity="danger" label="Usuń" :value="record.id" @click="handleRemove(record)"/>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<style scoped>
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: 1rem;
  background: #f9fafb;
}
.button-row {
  text-align: center;
}
.button {
  width: 80%;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 1rem;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  table td:last-child {
    border-bottom: 0;
  }
  .button {
    width: 100%;
    font-size: 0.8rem;
  }
}
</style>
