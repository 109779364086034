<script>
import axios from "axios";
export default {
  name: "UserData",
  emits: ['address-changed'],
  props: {
    token: String
  },
  created() {
    if(this.token) {
      this.getCustomerDetails();
    }
  },
  data() {
    return {
      customer: '',
      address: '',
      line1: '',
      line2: '',
      city: '',
      postalCode: ''
    }
  },
  methods: {
    async getCustomerDetails() {
      const details = {
        token: this.token
      };
      const response = await axios.post('/getCustomerDetails', details);
      if(response.data) {
        this.customer = response.data;
        let address = response.data.address;
        if(address) {
          this.line1 = address.line1;
          this.line2 = address.line2;
          this.city = address.city;
          this.postalCode = address.postal_code;
        }
      }
    },
    async onSubmit() {
      let address = {
        line1: this.line1,
        line2: this.line2,
        city: this.city,
        postal_code: this.postalCode,
        country: 'PL'
      };
      const details = {
        token: this.token,
        address: address,
        name: this.customer.name
      }
      const response = await axios.post('/updateCustomer', details);
      if(response.data) {
        this.showToast('success', 'Dane adresowe zapisane', '', 2000);
        this.$emit('address-changed', true);
      }
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <div class="form-container">
    <div class="field">
      <form class="flex flex-column gap-2 mt-4">
        <div class="field">
          <span class="p-float-label">
            <InputText id="customer.name" v-model="customer.name" type="text" class="input"/>
            <label class="label" for="customer.name">Imie i nazwisko</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="line1" v-model="line1" type="text" class="input"/>
            <label class="label" for="line1">Ulica, numer domu</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="line2" v-model="line2" type="text" class="input"/>
            <label class="label" for="line2">Numer mieszkania (opcjonanie)</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="city" v-model="city" type="text" class="input"/>
            <label class="label" for="city">Miasto</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="postal_code:" v-model="postalCode" type="text" class="input"/>
            <label class="label" for="postal_code:">Kod pocztowy</label>
          </span>
        </div>
        <div class="buttons-container">
          <Button class="button" label="Zapisz dane" @click="onSubmit"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.input {
  width: 20em;
  font-size: 1rem;
}
.field {
  margin-bottom: 2rem;
}
.button {
    width: 20em;
}
@media screen and (max-width: 640px) {
  .input {
    width: 100%;
    font-size: 1rem;
  }
  .label {
    font-size: 1rem;
  }
  .button {
    width: 100%;
    font-size: 1rem;
  }
}
</style>