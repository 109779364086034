<script>
export default {
  name: "PlanCardNew",
  emits: ['plan-selected'],
  props: {
    plan: Object,
    hideButtons: Boolean
  },
  data() {
    return {
      interval: '',
      mode: '',
      isSinglePayment: false
    }
  },
  created() {
    this.interval = 'zł/' + this.plan.interval;
    this.mode = this.plan.mode === 'subscription' ? 'Płatność odnawialna' : 'Płatność jednorazowa';
    this.isSinglePayment = this.plan.mode === 'payment';
  },
  methods: {
    handlePlanSelected(){
      this.$emit('plan-selected', this.plan);
    }
  }
}
</script>

<template>
  <div class="plan-container">
    <div class="plan-content">
      <h3 class="plan-title">{{plan.title}}</h3>
      <h1 class="price">{{plan.price}}</h1>
      <h3 class="interval">{{interval}}</h3>
<!--      <h4 class="mode">{{mode}}</h4>-->
      <p>Dostępne metody płatności:</p>
      <div class="grid">
        <div class="payment col-6">
          <p>Płatność odnawialna</p>
        </div>
        <div class="payment col-6">
          <i v-if="isSinglePayment === false" class="pi pi-check"></i>
          <i v-else class="pi pi-times"></i>
        </div>
        <div class="payment col-6">
         <p>Płatność jednorazowa</p>
        </div>
        <div class="payment col-6">
          <i v-if="isSinglePayment" class="pi pi-check"></i>
          <i v-else class="pi pi-times"></i>
        </div>
        <div class="payment col-6">
          <img class="payment-img" src="../assets/card.png" alt="card.png">
        </div>
        <div class="payment col-6">
          <i class="pi pi-check"></i>
        </div>
        <div class="payment col-6">
          <img class="payment-img" src="../assets/gpay.png" alt="card.png">
        </div>
        <div class="payment col-6">
          <i class="pi pi-check"></i>
        </div>
        <div class="payment col-6">
          <img class="payment-img" src="../assets/blik.png" alt="card.png">
        </div>
        <div class="payment col-6">
          <i v-if="isSinglePayment" class="pi pi-check"></i>
          <i v-else class="pi pi-times"></i>
        </div>
        <div class="payment col-6">
          <img class="payment-img" src="../assets/p24.png" alt="card.png">
        </div>
        <div class="payment col-6">
          <i v-if="isSinglePayment" class="pi pi-check"></i>
          <i v-else class="pi pi-times"></i>
        </div>
      </div>
      <Button v-if="hideButtons === false" class="button" label="Wybierz" @click="handlePlanSelected"></Button>
    </div>
  </div>
</template>

<style scoped>
.button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 94%;
}
.price {
  color: #8B5CF6;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 3rem;
}
.interval {
  color: #8B5CF6;
  margin-top: 0;
}
.plan-container {
  border: 2px solid #8B5CF6;
  border-radius: 15px;
  text-align: center;
  height: 540px;
}
.payment {
  text-align:center;
  margin:auto;
}
.plan-title {
  margin-bottom: 0;
}
.mode {
  margin-bottom: 0.5rem;
}
.payment-img {
  max-width:  60px;
  max-height: 28px;
}
.grid {
  margin-top: 1rem;
}
.pi-check {
  color: green;
  font-size: 1.2rem;
  font-weight: bold;
}
.pi-times {
  color: firebrick;
  font-size: 1.2rem;
  font-weight: bold;
}
.payment p {
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 640px) {
  .button {
    font-size: 1rem;
  }
}
</style>