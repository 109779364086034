<script>
export default {
  name: 'NoRecords'
}

</script>

<template>
  <Card style="width:80em">
    <template #title>Brak zadań</template>
    <template #content>
      <p class="m-0 task">
        Zmień kryteria losowania
      </p>
    </template>
  </Card>
</template>
<style scoped>
</style>