<script>
import Plans from "@/components/Plans.vue";

export default {
  name: "PriceList",
  components: {Plans}
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Cennik</h2>
      <div class="plan-body">
        <Plans :hide-buttons="true"></Plans>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*@media screen and (max-width: 640px) {
  .plan-body {
    padding: 2rem;
  }
}*/
</style>