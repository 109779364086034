<script>
export default {
  name: 'PlanCard',
  emits: ['plan-selected'],
  props: {
    plan: Object,
    disablePlanSelection: Boolean
  },
  methods: {
    handlePlanSelected(){
      this.$emit('plan-selected', this.plan);
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="content">
      <h3 class="plan-title">{{plan.title}}</h3>
      <h1 class="price">{{plan.price}}</h1>
      <h3 class="interval">zł</h3>
      <Button class="button" label="Wybierz" @click="handlePlanSelected" :disabled="disablePlanSelection"></Button>
    </div>
  </div>
</template>

<style scoped>
.container {
  border: 2px solid #8B5CF6;
  border-radius: 15px;
  text-align: center;
}
.price {
  color: #8B5CF6;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 3rem;
}
.plan-title {
  margin-bottom: 0;
}
.content {
  padding: 1em;
}
.interval {
  color: #8B5CF6;
  margin-top: 0;
}
.button {
  width: 20em;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 640px) {
  .content {
    padding: 0.5em;
  }
  .button {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

</style>