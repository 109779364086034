<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "RachunekPrawd",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) oblicza prawdopodobieństwo w modelu klasycznym;</p>
      <p>2) oblicza średnią arytmetyczną i średnią ważoną, znajduje medianę i dominantę;</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) oblicza prawdopodobieństwo warunkowe i stosuje wzór Bayesa, stosuje twierdzenie o prawdopodobieństwie całkowitym;</p>
      <p>2) stosuje schemat Bernoulliego.</p>
    </div>
  </div>
</template>

<style scoped>

</style>