<script>
import axios from "axios";
import TaskAnswerView from "@/components/TaskAnswerView.vue";

export default {
  name: "TaskQuestionView",
  components: { TaskAnswerView},
  created() {
    this.getQuestions();
  },
  emit:['show-toast', 'answers-reveled'],
  props: {
    taskId: String,
    isMultipleChoice: Boolean,
    customerId: String,
    isProofTask: Boolean
  },
  watch: {
    taskId() {
      this.getQuestions();
    }
  },
  data() {
    return {
      questions: [],
      correctAnswer: '',
      selectedAnswer: '',
      buttonLabel: '',
      selectedAnswers: [],
      isInteractionDisabled: false,
      questionToAnswers: new Map([]),
      resetAnswers: false,
      showQuestionLabel: false,
      scoredPoints: 0

    }
  },
  methods: {
    async getQuestions() {
      this.resetAnswers = false;
      this.isInteractionDisabled = false;
      if (Number.isInteger(this.taskId)) {
        this.questions.push({
          id: 1,
          taskId: this.taskId
        })
      } else {
        const response = await axios.get('/tasks/getQuestions', {
          params: {
            taskId: this.taskId
          }
        });
        if (response.data.length > 0) {
          this.questions = response.data;
          this.showQuestionLabel = this.questions.length > 1;
        }
        this.buttonLabel = this.questions.length === 1 ? 'Sprawdź odpowiedź' : 'Sprawdź odpowiedzi';
      }
    },
    handleAnswersSelected({ questionId, selectedAnswers }) {
      this.selectedAnswers = this.selectedAnswers.filter(answer => answer.questionId !== questionId);
      this.selectedAnswers.push({ questionId, selectedAnswers });
    },
    async checkAnswers() {
      if(this.questions.length !== this.selectedAnswers.length) {
        this.showToast('error', 'Błąd','Wybierz odpowiedź do każdego pytania', 2000);
        return;
      }
      this.isInteractionDisabled = true;
      this.correctAnswers = [];
      const response = await axios.get('/tasks/checkAnswers', {
        params: {
          selectedAnswers: this.selectedAnswers,
          customerId: this.customerId
        }
      });
      this.scoredPoints = response.data.points;
      if(this.scoredPoints > 0) {
        const pointsLabel = this.scoredPoints === 1 ?  '+1 punkt' : `+${this.scoredPoints} punkty`;
        this.showToast('success', 'Sukces',pointsLabel, 2000);
      }
      if(response.data.pointsAlreadyScored) {
        this.showToast('info', 'Informacja', 'To zadanie zostało już nagrodzone punktami!', 2000);
      }
      this.questionToAnswers = response.data.map;
      this.resetAnswers = true;
      this.selectedAnswers = [];
      this.$emit('answers-reveled');
    },
    showToast(severity, summary, detail, life) {
      this.$emit('show-toast', {
        severity: severity,
        summary: summary,
        detail: detail,
        life: life
      });
    },
  }
}
</script>

<template>
  <div v-for="(question, index)  in questions" :key="question.id" class="items-center">
    <div v-if="showQuestionLabel" class="text-center">
      <h2>Pytanie {{index +1}}</h2>
    </div>
    <TaskAnswerView v-else
        :question-id="question.id"
        :task-id="taskId"
        :correct-answer-from-parent="correctAnswer"
        :is-multiple-choice="isMultipleChoice"
        :button-label="buttonLabel"
        :is-interaction-disabled="isInteractionDisabled"
        :correct-answers="questionToAnswers[question.id]?.correctAnswers || []"
        :incorrect-answers="questionToAnswers[question.id]?.incorrectAnswers || []"
        :reset-answers="resetAnswers"
        @answers-selected="handleAnswersSelected">
    </TaskAnswerView>
  </div>
  <div v-if="!isProofTask" class="button-container">
    <Button class="button button-border"
            :label="buttonLabel"
            icon="pi pi-search"
            @click="checkAnswers"
            :disabled="isInteractionDisabled">
    </Button>
  </div>
</template>

<style scoped>
.button-container {
  text-align: center;
}
.button {
  width: 20rem;
}
@media screen and (max-width: 640px) {
  .button {
    width: 100%;
  }
}
</style>