<script>
import {units} from '@/assets/materials';

export default {
  name: "Education",
  data() {
    return {
      units: units,
      activeIndex: -1
    }
  },
  methods: {
    handleUnitSelected(event) {
      this.$router.push({name: 'dzial', params: {name: event.subCategory}});
    },
    handleRequirement() {
      this.$router.push('/wymagania2025');
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Materialy do nauki</h2>
      <div class="container">
        <Button class="button single-button" text label="Wymagania szczegółowe na maturze 2025"
                @click="handleRequirement()"></Button>
        <h3 class="default-color">Wybierz dział:</h3>
        <p class="custom-p">Strona w przygotowaniu, brakujące działy zostaną dodane wkrótce.</p>
        <Accordion v-model:active-index="activeIndex">
          <AccordionTab v-for="unit of units" :key="unit.id" :header="unit.title">
            <div v-for="subUnit of unit.subunits" :key="subUnit.id">
              <Button class="button" text :label="subUnit.name" @click="handleUnitSelected(subUnit)"></Button>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<style scoped>
.single-button {
  padding-left: 0;
}

@media screen and (max-width: 640px) {
  .button {
    font-size: 0.8rem;
  }

  .single-button {
    font-size: 1rem;
  }
}
</style>