<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Kombinatoryka",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) zlicza obiekty w prostych sytuacjach kombinatorycznych;</p>
      <p>2) zlicza obiekty, stosując reguły mnożenia i dodawania (także łącznie) dla dowolnej liczby czynności, np.:</p>
        <ol type="a">
          <li>
            <LatexData class="small-font" equ="obliczenie, ile jest czterocyfrowych nieparzystych liczb całkowitych dodatnich takich, że w ich zapisie dziesiętnym występuje dokładnie jedna cyfra$\ 1$ i dokładnie jedna cyfra$\ 2$,"></LatexData>
          </li>
          <li>
            <LatexData class="small-font" equ="obliczenie, ile jest czterocyfrowych parzystych liczb całkowitych dodatnich takich, że w ich zapisie dziesiętnym występuje dokładnie jedna cyfra$\ 0$ i dokładnie jedna cyfra$\ 1$."></LatexData>
          </li>
        </ol>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) oblicza liczbę możliwych sytuacji, spełniających określone kryteria, z wykorzystaniem reguły mnożenia i dodawania (także łącznie) oraz wzorów na liczbę: permutacji, kombinacji i wariacji;</p>
      <p>2) stosuje współczynnik dwumianowy (symbol Newtona) i jego własności przy rozwiązywaniu problemów kombinatorycznych.</p>
    </div>
  </div>
</template>

<style scoped>

</style>