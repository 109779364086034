<script>
export default {
  name: "CommentLatex",
  props: ['equ', 'class'],
  emits:['equ-rendered'],
  created() {
    this.processEqu(this.equ);
  },
  data() {
    return {
      showEqu: false,
      equToDisplay: [],
      mathClass: ''
    }
  },
  methods: {
    processEqu(equ) {
      this.mathClass = this.class ? this.class : 'math';
      let subString = equ.split(';;');
      let subStringAsObjects = [];
      subString.forEach(singleText => {
        singleText = singleText.replace('\n', '');
        if(singleText.includes('$')) {
          subStringAsObjects.push({
            type: 'math-inline',
            text: singleText
          });
        } else {
          subStringAsObjects.push({
            type: 'p',
            text: singleText
          });
        }
      });
      this.solutionAsArray = subStringAsObjects;
      subStringAsObjects.forEach(singleEqu => {
        if (singleEqu.type === 'math-inline') {
          singleEqu.substring = singleEqu.text.split('$');
        }
      });
      this.equToDisplay = subStringAsObjects;
      this.$emit('equ-rendered');
    }
  }
}
</script>

<template>
  <div v-for="singleEqu of equToDisplay" v-bind:key="equ.text">
    <p v-if="singleEqu.type === 'p'"  :class="mathClass">
      {{ singleEqu.text }}
    </p>
    <p v-if="singleEqu.type === 'math-inline'"  :class="mathClass">
      <template v-for="subText of singleEqu.substring" v-bind:key="subText">
        <math-jax v-if="subText.includes('\\')" :latex="subText"></math-jax>
        <template v-else>{{ subText }}</template>
      </template>
    </p>
  </div>
</template>

<style scoped>
.math {
  font-size: 1.2rem;
}
.small-font {
  font-size: 1rem;
}
</style>