<script>
import axios from "axios";
import {months, units, labelDetails, columns} from '@/assets/utils.js';
import SignupModal from "@/components/SignupModal.vue";
import TaskCardView from "@/components/TaskCardView.vue";
import DataTableResponsive from "@/components/DataTableResponsive.vue";

export default {
  name: "FavoriteTasks",
  components: {TaskCardView, SignupModal, DataTableResponsive},
  props: {
    token: String
  },
  created() {
    if (this.token) {
      this.getRecords();
    }
  },
  data() {
    return {
      records: [],
      months: months,
      units: units,
      labelDetails: labelDetails,
      showModal: false,
      selectedRecord: '',
      columns: columns,
      customerId: ''
    }
  },
  methods: {
    async getRecords() {
      this.records = [];
      const details = {
        token: this.token
      };
      if (!this.customerId) {
        const customerDetails = await axios.post('/getCustomerDetails', details);
        if (customerDetails.data) {
          this.customerId = customerDetails.data.id;
        }
      }
      const response = await axios.get('/getFavoriteTasks', {
        params: {
          customerId: this.customerId
        }
      });
      response.data.forEach(task => {
        const month = this.months.find(month => month.name === task.month).code;
        let label = this.labelDetails.get(task.month);
        const record = {
          id: task.taskId,
          exam: month + ' 20' + task.year + ' - ' + label,
          taskNumber: task.taskNumber,
          type: task.type === 'p' ? 'podstawa' : 'rozszerzenie',
          unit: this.units.find(unit => unit.name === task.unit).label,
          label: task.label,
        }
        this.records.push(record);
      });
    },
    async handleRecordSelect(event) {
      this.showModal = !this.showModal;
      this.selectedRecord = event;
    },
    async handleRemoveFavoriteTask(event) {
      const response = await axios.post('/removeFavoriteTask', {
        taskId: event.id,
        customerId: this.customerId
      });
      if (response.data.status === 200) {
        this.isFavoriteTask = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Sukces',
          detail: 'Zadanie usunięte z ulubionych',
          group: 'tc',
          life: 2000
        });
        await this.getRecords();
      }
    },
  }
}
</script>

<template>
  <div v-if="records.length > 0">
    <DataTableResponsive :records="records" :columns="columns" @record-selected="handleRecordSelect"
                         @record-removed="handleRemoveFavoriteTask"></DataTableResponsive>
  </div>
  <div v-else>
    <h3>Brak polubionych zadań</h3>
  </div>
  <Dialog v-model:visible="showModal" modal header="Zadanie" :style="{ width: '65rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <TaskCardView :task-id="selectedRecord.id" :show-answers="false"></TaskCardView>
  </Dialog>
</template>

<style scoped>
</style>