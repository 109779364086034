<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "RachunekRoz",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) rozwiązuje zadania optymalizacyjne w sytuacjach dających się opisać funkcją kwadratową.</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) oblicza granice funkcji (w tym jednostronne);</p>
      <p>2) stosuje własność Darboux do uzasadniania istnienia miejsca zerowego funkcji;</p>
      <p>3) stosuje definicję pochodnej funkcji, podaje interpretację geometryczną i fizyczną pochodnej;</p>
      <p>4)oblicza pochodną funkcji potęgowej o wykładniku rzeczywistym oraz oblicza pochodną, korzystając z twierdzeń o pochodnej sumy, różnicy, iloczynu, ilorazu i funkcji złożonej;</p>
      <p>5) stosuje pochodną do badania monotoniczności funkcji;</p>
      <p>6) rozwiązuje zadania optymalizacyjne z zastosowaniem pochodnej.</p>
    </div>
  </div>
</template>

<style scoped>

</style>