<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "UklRow",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) rozwiązuje układy równań liniowych z dwiema niewiadomymi, podaje interpretację geometryczną układów oznaczonych, nieoznaczonych i sprzecznych;</p>
      <p>2) stosuje układy równań do rozwiązywania zadań tekstowych;</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) rozwiązuje układy równań liniowych i kwadratowych z dwiema niewiadomymi, które można sprowadzić do równania kwadratowego lub liniowego, a które nie są trudniejsze niż</p>
      <LatexData class="small-font" equ="$\left\{ \begin{array}{cl}x^2+y^2+ax+by=c \\x^2+y^2+dx+ey=f\end{array} \right.$;;"></LatexData>
    </div>
  </div>
</template>

<style scoped>

</style>