<script>
import {units, types} from '@/assets/utils';
import axios from 'axios';
import TaskCard from "@/components/TaskCard.vue";
import NoRecords from "@/components/NoRecords.vue";

export default {
  name: "Home",
  components: {
    TaskCard,
    NoRecords
  },
  created() {
    this.checkUser();
  },
  data() {
    return {
      units: units,
      selectedUnits: [],
      showCard: false,
      showAnswer: false,
      task: {},
      types: types,
      selectedTypes: [],
      allUnits: [],
      showNoRecords: false,
      previousSelectedUnits: [],
      previousSelectedTypes: [],
      records: [],
      user: '',
      isFavoriteTask: false,
      favoriteTasks: [],
      hideButtons: false,
      searchLogId: '',
      comments: [],
    }
  },
  methods: {
    async checkUser() {
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
        await this.getFavoriteTasks();
        if (this.user.isPremiumUser === 0) {
          this.$router.push('/profil');
        } else {
          this.checkTask();
        }
      } else {
        // this.$router.push('/');
      }
    },
    checkTask() {
      this.task = this.getCookie('lz_task');
      if (this.task) {
        this.showCard = true;
        this.checkFavoriteValue();
      }
    },
    async getFavoriteTasks() {
      const response = await axios.get('/getFavoriteTasks', {
        params: {
          customerId: this.user.customerId
        }
      });
      this.favoriteTasks = response.data;
    },
    checkFavoriteValue() {
      this.isFavoriteTask = this.favoriteTasks.find(record => record.taskId === this.task.id);
    },
    async chooseTask() {
      let tempUnits = [...this.previousSelectedUnits];
      let tempTypes = [...this.previousSelectedTypes];

      let units = this.getUnits(this.selectedUnits);
      this.previousSelectedUnits = units;

      let types = this.getTypes(this.selectedTypes);
      this.previousSelectedTypes = types;

      let unitsChanged = JSON.stringify(tempUnits) !== JSON.stringify(units);
      let typesChanged = JSON.stringify(tempTypes) !== JSON.stringify(types);
      if (unitsChanged || typesChanged) {
        const response = await axios.get('/tasks', {
          params: {
            units: units,
            types: types,
            customerId: this.user.customerId
          }
        });
        this.records = response.data.records;
        this.searchLogId = response.data.searchLogId;
      }
      if (this.records.length > 0) {
        this.showNoRecords = false;
        const task = this.doShuffle();
        if (task) {
          this.task = task;
          this.checkFavoriteValue();
          this.showAnswer = false;
          if (this.user) {
            const response = await axios.post('/tasks/updateViews', {
              id: task.id,
              user: this.user,
              searchLogId: this.searchLogId
            });
            this.setCookie(task);
            if (response.data.status === 400) {
              const message = response.data.message;
              this.$toast.add({severity: 'error', summary: 'Subskrypcja', detail: message, group: 'tc', life: 2000});
              this.$router.push('/profil');
            }
          }
          if (!this.showCard) {
            this.showCard = true;
          }
          this.hideButtons = !this.user;
          await this.$refs.taskCard.refreshImages();
        }
      } else {
        this.showCard = false;
        this.showNoRecords = true;
      }
    },
    doShuffle() {
      const random = Math.floor(Math.random() * this.records.length);
      const task = this.records[random];
      this.records = this.records.filter(record => record.id !== task.id);
      return task;
    },
    handleShowAnswerChange(showAnswer) {
      this.showAnswer = showAnswer;
    },
    async handleAddFavoriteTask(event) {
      const response = await axios.post('/addFavoriteTask', {
        task: event,
        customerId: this.user.customerId
      });
      if (response.data.status === 200) {
        this.isFavoriteTask = true;
        this.$toast.add({
          severity: 'success',
          summary: 'Sukces',
          detail: 'Zadanie dodane do ulubionych',
          group: 'tc',
          life: 2000
        });
      }
    },
    async handleRemoveFavoriteTask(event) {
      const response = await axios.post('/removeFavoriteTask', {
        taskId: event,
        customerId: this.user.customerId
      });
      if (response.data.status === 200) {
        this.isFavoriteTask = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Sukces',
          detail: 'Zadanie usunięte z ulubionych',
          group: 'tc',
          life: 2000
        });
      }
    },
    getUnits(selectedUnits) {
      let unitsToReturn = [];
      if (selectedUnits.length === 0) {
        if (this.allUnits.length === 0) {
          this.units.forEach(unit => {
            unitsToReturn.push(unit.name);
          });
          this.allUnits = unitsToReturn;
        }
        unitsToReturn = [...this.allUnits];
      } else {
        unitsToReturn = selectedUnits;
      }
      return unitsToReturn;
    },
    getTypes(selectedTypes) {
      let typesToReturn = [];
      if (selectedTypes.length === 0) {
        typesToReturn = ['p', 'r'];
      } else {
        typesToReturn = selectedTypes;
      }
      return typesToReturn;
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    setCookie(task) {
      this.$cookies.set('lz_task', task);
    }

  }
}
</script>
<template>
  <Toast position="top-center" group="tc"/>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <h2>Poziom</h2>
        <div class="grid mb-5">
          <div class="col-12 md:col-6 lg:col-2 flex" v-for="type in types" :key="type.id">
            <Checkbox v-model="selectedTypes" :inputId="type.id" name="units" :value="type.name"
                      :disabled="type.isDisabled"/>
            <label :for="type.id" class="unit-label"> {{ type.label }}</label>
          </div>
        </div>
        <h2>Dział</h2>
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-2 flex" v-for="unit in units" :key="unit.id">
            <Checkbox v-model="selectedUnits" :inputId="unit.id" name="units" :value="unit.name"/>
            <label :for="unit.id" class="unit-label"> {{ unit.label }}</label>
          </div>
        </div>
        <Button class="mt-2 button button-border" label="Losuj zadanie" @click="chooseTask" icon="pi pi-question"
                :disabled="hideButtons"></Button>
      </div>
    </div>
  </div>
  <div class="card card-center fadein" v-if="showCard">
    <TaskCard ref="taskCard"
              :task="task"
              :showAnswer="showAnswer"
              :isFavoriteTask="isFavoriteTask"
              :hide-buttons="hideButtons"
              :customer-id="user.customerId"
              @show-answer="handleShowAnswerChange"
              @add-favorite-task="handleAddFavoriteTask"
              @remove-favorite-task="handleRemoveFavoriteTask"></TaskCard>
  </div>
  <div class="card card-center fadein" v-if="showNoRecords">
    <NoRecords></NoRecords>
  </div>
</template>

<style scoped>
.card-center {
  justify-content: center;
  align-items: center;

}

.card-filter {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
}

.unit-label {
  margin-left: 0.25rem;
}

.fadein {
  animation-name: fadein-animation;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

.button {
  margin-top: 1rem;
  width: 15rem;
}

@media screen and (max-width: 640px) {
  .button {
    width: 100%;
    font-size: 1em;
  }
}

@keyframes fadein-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>