<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Ciagi",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) oblicza wyrazy ciągu określonego wzorem ogólnym;</p>
      <p>2) oblicza początkowe wyrazy ciągów określonych rekurencyjnie;</p>
      <p>3) w prostych przypadkach bada, czy ciąg jest rosnący, czy malejący;</p>
      <p>4) sprawdza, czy dany ciąg jest arytmetyczny lub geometryczny;</p>
      <LatexData class="small-font"
                 equ="5) stosuje wzór na$\ n$-ty wyraz i na sumę$\ n$ początkowych wyrazów ciągu arytmetycznego;"></LatexData>
      <LatexData class="small-font"
                 equ="6) stosuje wzór na$\ n$-ty wyraz i na sumę$\ n$ początkowych wyrazów ciągu geometrycznego;"></LatexData>
      <p>7) wykorzystuje własności ciągów, w tym arytmetycznych i geometrycznych, do rozwiązywania zadań, również
        osadzonych w kontekście praktycznym.
      </p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <LatexData class="small-font" equ="1) oblicza granice ciągów, korzystając z granic ciągów typu $\dfrac1n,\;\sqrt[n]{a}$ oraz twierdzeń o granicach sumy, różnicy, iloczynu i ilorazu ciągów zbieżnych, a także twierdzenia o trzech ciągach;"></LatexData>
      <p>2) rozpoznaje zbieżne szeregi geometryczne i oblicza ich sumę.
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>