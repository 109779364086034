<script>
import { MathJax } from 'mathjax-vue3'
export default {
  name: "About",
  components: {
    MathJax
  },
  data() {
    return {
      formula: 'x^2 - 5x = 10'
    }
  }
}
</script>

<template>
  <div class="card card-center mt-3">
    <Card style="width:80em">
      <template #content>
        <div class="container">
<!--          <h1>Witaj na stronie <span class="website">losujzadanie.pl - zadania maturalne z matematyki</span></h1>
          <p class="item">Stworzonej przez pasjonowane małżeństwo: Przemka, doświadczonego
          programistę, i Anię, wytrawną nauczycielkę matematyki. Nasza aplikacja oferuje kompleksowe materiały do nauki
          matematyki na poziomie maturalnym, oparte na analizie matur z poprzednich lat.</p>

          <p class="item">Dzięki naszemu innowacyjnemu podejściu i zaawansowanej technologii, uczniowie mogą skutecznie przygotować się do
          egzaminów maturalnych z matematyki. Nasza aplikacja oferuje interaktywne zadania, szczegółowe wyjaśnienia krok po
          kroku, które pomagają w utrwaleniu wiedzy i doskonaleniu umiejętności.</p>

          <p class="item">Nie ważne, czy dopiero zaczynasz przygodę z matematyką czy jesteś już zaawansowanym uczniem, nasza platforma
          dostosowuje się do Twoich potrzeb i umiejętności. Dzięki nam matematyka stanie się nie tylko zrozumiała, ale także
          ciekawa i przyjemna!</p>

          <p class="item">Dołącz do naszej społeczności już dziś i odkryj, jak łatwo może być osiągnąć sukces na egzaminie maturalnym z
          matematyki. Zaufaj losujzadanie.pl – Twojemu najlepszemu partnerowi w nauce matematyki!</p>-->
        </div>
      </template>
    </Card>
  </div>

</template>

<style scoped>
.card-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container {
  padding: 1rem;
}
.website {
  color: #8B5CF6;
}
.item {
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-word;
}
</style>