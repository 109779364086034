<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Sterometria",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) rozpoznaje wzajemne położenie prostych w przestrzeni, w szczególności proste prostopadłe nieprzecinające się;</p>
      <p>2) posługuje się pojęciem kąta między prostą a płaszczyzną oraz pojęciem kąta dwuściennego między półpłaszczyznami;</p>
      <p>3) rozpoznaje w graniastosłupach i ostrosłupach kąty między odcinkami (np. krawędziami, krawędziami i przekątnymi) oraz kąty między ścianami, oblicza miary tych kątów;</p>
      <p>4) rozpoznaje w walcach i w stożkach kąt między odcinkami oraz kąt między odcinkami i płaszczyznami (np. kąt rozwarcia stożka, kąt między tworzącą a podstawą), oblicza miary tych kątów;</p>
      <p>5) oblicza objętości i pola powierzchni graniastosłupów, ostrosłupów, walca, stożka i kuli, również z wykorzystaniem trygonometrii;</p>
      <p>6) wykorzystuje zależność między objętościami brył podobnych.</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) zna i stosuje twierdzenie o prostej prostopadłej do płaszczyzny i o trzech prostopadłych;</p>
      <p>2) wyznacza przekroje sześcianu i ostrosłupów prawidłowych oraz oblicza ich pola, także z wykorzystaniem trygonometrii.</p>
    </div>
  </div>
</template>

<style scoped>

</style>