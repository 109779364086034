<script>
import axios from "axios";
import { intervalMap,  freePlan } from '@/assets/utils.js';
import PlanCardFree from "@/components/PlanCardFree.vue";
import LoginModal from "@/components/LoginModal.vue";
import Plans from "@/components/Plans.vue";

export default {
  name: "Subscription",
  emits: ['show-spinner'],
  components: {Plans, LoginModal, PlanCardFree},
  props: {
    token: String
  },
  created() {
    this.showSpinner(false);
    if (this.token) {
      this.getActiveSubscription();
    }
  },
  data() {
    return {
      subscriptions: [],
      activeSubscription: '',
      trialSubscription: '',
      finishedSubscription: '',
      showActiveSubscription: false,
      noActiveSubscription: false,
      freePlan: freePlan,
      endDateTitle: '',
      showFreeSub: false,
      freeSubtitle: '',
      premiumSubtitle: '',
      isActiveCancel: false,
      showResumeButton: false,
      subHeader: 'Aktywny plan',
      subStatuses: ['active', 'trialing'],
      showTrailHeader: false,
      showSinglePaymentHeader: false,
      showAddressModal: false,
      showAdminMessage: false,
      buttonDisabled: false,
      subMode: '',
      showFreePlanExpired: false
    }
  },
  methods: {
    async getActiveSubscription() {
      this.showSpinner(true);
      const details = {
        token: this.token
      };
      const response = await axios.post('/getSubscriptions', details);
      this.subscriptions = response.data.subscriptions.list;
      this.showFreeSub = response.data?.user?.freePlanExpired === 0 && response.data?.user?.freePlanActivated !== 1;
      this.showFreePlanExpired = response.data?.user?.freePlanExpired === 1;
      this.freeSubtitle = this.showFreeSub ? 'Wypróbuj za darmo' : 'Darmowy limit wykorzystany';
      this.premiumSubtitle = this.showFreeSub ? 'Lub wykup dostęp premium' : 'Skorzystaj z naszych płatnych planów ';
      if (this.subscriptions.length > 0) {
        this.showSpinner(false);
        let dateNow = Date.now() / 1000;
        this.finishedSubscription = this.subscriptions.filter(sub => sub.status !== 'active');
        let activeSub = this.subscriptions.find(sub => this.subStatuses.includes(sub.status) && sub.current_period_end > dateNow);
        let activeFreeSub = this.subscriptions.find(sub => sub.numberOfFreeTasks > 0);
        if (activeSub) {
          this.handleActiveSubscription(activeSub);
          this.showActiveSubscription = true;
          this.refreshDetails(activeSub);
        } else if(activeFreeSub){
          this.handleActiveSubscription(activeFreeSub);
          this.showActiveSubscription = true;
          this.refreshDetails(activeFreeSub);
        } else {
          this.noActiveSubscription = true;
        }
      }
      else {
        this.showSpinner(false);
        this.showAdminMessage = response.data?.user?.isPermPremiumUser;
        this.noActiveSubscription = !this.showAdminMessage;
      }
    },
    handleActiveSubscription(subscription) {
      this.buttonDisabled = subscription.plan.amount === 0;
      this.activeSubscription = {
        id: subscription.id,
        createdDate:  subscription.created ? this.getTime(subscription.created): '',
        endDate: this.getTime(subscription.current_period_end),
        plan:  subscription.object !== 'singlePaymentPlan' ? this.getPlan(subscription.plan) : '',
        numberOfFreeTasks: subscription.object === 'freeTasks' ? subscription.numberOfFreeTasks : ''
      };
    },
    getTime(date) {
      const newDate = new Date(date * 1000);
      return newDate.toLocaleDateString('pl-PL');
    },
    getPlan(plan) {
      const amount = plan.amount / 100;
      const isFreePlan = plan.amount === 0;
      const amountLocal = amount.toLocaleString("pl", {useGrouping: false, minimumFractionDigits: 2});
      return  isFreePlan ? amountLocal + 'zł' : amountLocal + ' zł/' + intervalMap.get(plan.interval);
    },
    async cancelSubscription() {
      let details = {
        token: this.token,
        activeSub: this.activeSubscription
      };
      const response = await axios.post('/cancelSubscription', details);
      if (response.data) {
        this.refreshDetails(response.data);
        this.showToast('success', 'Subskrypcja anulowana poprawnie', '', 2000);
      }
    },
    confirmSubCancellation() {
      this.$confirm.require({
        message: 'Czy na pewno chcesz anulować subskrypcję?',
        header: 'Potwierdź wybór',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Wróć',
        acceptLabel: 'Anuluj subskrypcję',
        rejectClass: 'p-button-secondary p-button-outlined',
        acceptClass: 'p-button-danger',
        accept: () => {
          this.cancelSubscription();
        }
      });
    },
    async handlePlanSelected(event) {
      this.showSpinner(true);
      const details = {
        token: this.token,
        plan: event
      };
      const endpoint = event.id === 0 ? '/createFreeTrial' : '/createPayment';
      const response = await axios.post(endpoint, details);
      if (response.data.url) {
        window.location = response.data.url;
      } else {
        if (response.sqlMessage) {
          this.showToast('error', 'Błąd', response.sqlMessage, 2000);
          this.showSpinner(false);
        } else {
          this.showToast('success', 'Darmowy plan aktywowany', '', 2000);
          this.showSpinner(false);
          this.$router.push('/losuj-zadanie');
        }
      }
    },
    refreshDetails(activeSub) {
      const isFreeSubActive =  activeSub.plan.amount === 0;
      const singlePaymentMode = activeSub.object === 'singlePaymentPlan';
      this.subMode = activeSub.object;
      this.endDateTitle = activeSub.cancel_at_period_end || singlePaymentMode ? 'Data wygaśnięcia:' : 'Następna płatność';
      this.isActiveCancel = !activeSub.cancel_at_period_end;
      this.showResumeButton = activeSub.cancel_at_period_end;
      this.subHeader = activeSub.cancel_at_period_end ? 'Anulowana subskrypcja' : 'Aktywny plan';
      this.showTrailHeader = isFreeSubActive;
      this.showSinglePaymentHeader = singlePaymentMode;
    },

    async checkCustomerDetails() {
      const details = {
        token: this.token
      };
      const response = await axios.post('/getCustomerDetails', details);
      if (response.data) {
        if (response.data.address) {
          let address = response.data.address;
          return address.city !== '' && address.line1 !== '' && address.postal_code !== '';
        }
        return false;
      }
    },
    showSpinner(value) {
      this.$emit('show-spinner', value);
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <ConfirmDialog></ConfirmDialog>
  <div class="main">
    <div class="admin-container" v-if="showAdminMessage">
      <div class="mb-4 title">
        <h3>Subskrypcja aktywna do odwołania przez administratora</h3>
      </div>
    </div>
    <div class="plan-card" v-if="showActiveSubscription">
      <div v-if="subMode === 'singlePaymentPlan'">
        <h3>{{ subHeader }}</h3>
        <h4>Jednorazowa płatność</h4>
        <div class="grid">
          <div class="field col-6">
            {{ endDateTitle }}
          </div>
          <div class="field col-6">
            {{ activeSubscription.endDate }}
          </div>
        </div>
      </div>
      <div v-if="subMode === 'freeTasks'">
        <h3>{{ subHeader }}</h3>
        <h4>Pakiet darmowych zadań</h4>
        <div class="grid">
          <div class="field col-6">
            Pozostało losowych zadań:
          </div>
          <div class="field col-6">
            {{ activeSubscription.numberOfFreeTasks }}
          </div>
        </div>
      </div>
      <div v-if="subMode === 'subscription'">
        <h3>{{ subHeader }}</h3>
        <div class="grid">
          <div class="col-6">
            Początek subskrypcji:
          </div>
          <div class="field col-6">
            {{ activeSubscription.createdDate }}
          </div>
          <div class="field col-6">
            {{ endDateTitle }}
          </div>
          <div class="field col-6">
            {{ activeSubscription.endDate }}
          </div>
          <div class="field col-6">
            Aktywny plan:
          </div>
          <div class="field col-6">
            {{ activeSubscription.plan }}
          </div>
        </div>
        <div class="mt-1">
          <div v-if="isActiveCancel">
            <Button class="button" label="Deaktywuj" severity="danger" @click="confirmSubCancellation" :disabled="buttonDisabled"/>
          </div>
        </div>
      </div>
    </div>

    <div class="plan-container" v-if="noActiveSubscription || subMode === 'freeTasks'">
      <div class="mb-4">
        <h2  v-if="noActiveSubscription" class="title"> Brak aktywnej subskrypcji</h2>
      </div>
      <div class="subtitle" v-if="showFreePlanExpired">
        <h3>{{ freeSubtitle }}</h3>
      </div>
      <div class="custom-grid" v-if="showFreeSub">
        <div class="single-plan">
          <PlanCardFree :plan="freePlan" @plan-selected="handlePlanSelected"></PlanCardFree>
        </div>
      </div>
      <div class="subtitle">
        <h3>{{ premiumSubtitle }}</h3>
      </div>
    </div>
    <div v-if="subMode === 'freeTasks' || noActiveSubscription" class="plan-container">
<!--      <h3 class="title text-center">Do skorzystania z nielimitowanych losowań oraz arkuszy maturalnych należy wykupić jeden z pakietów płatnych.</h3>-->
      <Plans @plan-selected="handlePlanSelected" :hide-buttons="false"></Plans>
    </div>
  </div>
</template>

<style scoped>
.plan-card {
  border-radius: 6px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 1em;
  width: 25em;
}

.plan-container {
  margin-top: 2em;
}

.subtitle {
  text-align:center;
}
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.single-plan {
  width: 35%;
  margin-left: 1em;
  margin-right: 1em;
}
.missing-address > h4  {
  color: #f13030;
}
.button {
  width: 100%;
}
.p-button {
  background-color: #8B5CF6;
}

@media screen and (max-width: 640px) {
  .plan-card {
    width: 100%;
  }
  .single-plan {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2em;
  }
  .title {
    font-size: 1rem;
    text-align:center;
  }
  .subtitle {
    font-size: 0.8rem;
  }
  .missing-address {
    text-align:center;
  }
  .button {
    font-size: 0.8rem;
  }
}
</style>