<script>
import LatexData from "@/components/LatexData.vue";
import axios from "axios";
import {units} from "@/assets/utils";
import TaskQuestion from "@/components/TaskQuestion.vue";

export default {
  name: "Task",
  components: {TaskQuestion, LatexData},

  props: {
    task: Object
  },
  data() {
    return {
      units: units,

    }
  },
  updated() {
    this.renderAnswer(this.task.aUrl);
    this.renderSolution(this.task.sUrl);
  },
  methods: {
    async onSubmit() {
      const token = this.getCookie('lz_token');
      const response = await axios.post('/tasks/update', {
        task: this.task,
        token: token
      });
      if (response.status === 200) {
        this.$toast.add({severity: 'success', summary: 'Sukces', detail: 'Zadanie zapisane', group: 'tc', life: 2000});
      } else {
        this.$toast.add({severity: 'danger', summary: 'Bład', detail: res, group: 'tc', life: 2000});
      }
    },
    renderSolution(event) {
      this.task.sUrl = event;
      this.$refs.solutionLatex.processEqu(event);
    },
    renderAnswer(event) {
      this.task.aUrl = event;
      this.$refs.answerLatex.processEqu(event);
    },

    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <TabView class="tab-view">
    <TabPanel header="Zadanie">
      <div class="mb-4">
        <Button type="submit" class="save-button button-border" label="Zapisz" icon="pi pi-check" @click="onSubmit"/>
      </div>
      <form class="flex flex-column gap-2">
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.id" v-model="task.id" type="text" class="input"/>
            <label for="task.id">Id zadania</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.taskNumber" v-model="task.taskNumber" type="text" class="input"/>
            <label for="task.taskNumber">Numer zadania</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.label" v-model="task.label" type="text" class="input"/>
            <label for="task.label">Opis matury</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.month" v-model="task.month" type="text" class="input"/>
            <label for="task.month">Miesiac</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.year" v-model="task.year" type="text" class="input"/>
            <label for="task.year">Rok</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.unit" v-model="task.unit" type="text" class="input"/>
            <label for="task.unit">Dział</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.showSolutionImg" v-model="task.showSolutionImg" type="number" class="input"/>
            <label for="task.showSolutionImg">Pokaż rysunek pomocniczny</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="task.isMultipleChoice" v-model="task.isMultipleChoice" type="number" class="input"/>
            <label for="task.isMultipleChoice">Wielkrotny wybór?</label>
          </span>
        </div>
        <div>
          <div class="field">
          <span class="p-float-label">
            <InputText id="task.qUrl" v-model="task.qUrl" type="text" class="input"/>
            <label for="task.qUrl">Pytanie - url</label>
          </span>
          </div>
          <div class="field">
          <span class="p-float-label">
            <InputText id="task.imgUrl" v-model="task.imgUrl" type="text" class="input"/>
            <label for="task.imgUrl">Rysunek pomocniczny - url</label>
          </span>
          </div>
          <div>
            <LatexData ref="answerLatex" :equ="task.aUrl"></LatexData>
          </div>
          <div class="field">
              <span class="p-float-label">
                <Textarea class="textarea-a" v-model="task.aUrl" @input="renderAnswer(task.aUrl)"></Textarea>
                 <label for="task.aUrl">Odpowiedź</label>
              </span>
          </div>
          <div class="field text-center">
            <h4>Pytanie:</h4>
            <img :src="task.qUrl">
          </div>
          <div v-if="task.showSolutionImg" class="field text-center">
            <h4>Rysunek pomocniczny:</h4>
            <img :src="task.imgUrl">
          </div>
          <div class="field">
              <span class="p-float-label">
                <Textarea class="textarea" v-model="task.sUrl" @input="renderSolution(task.sUrl)"></Textarea>
                 <label for="task.sUrl">Rozwiązanie</label>
              </span>
          </div>
          <div class="solution-container">
            <LatexData ref="solutionLatex" :equ="task.sUrl"></LatexData>
          </div>
        </div>
      </form>
    </TabPanel>
    <TabPanel header="Odpowiedzi">
      <TaskQuestion :task-id="task.id" :q-url="task.qUrl" :answer="task.aUrl"></TaskQuestion>
    </TabPanel>
  </TabView>
</template>

<style scoped>
.input {
  width: 30em;
}

.textarea {
  min-width: 40rem;
  min-height: 30rem;
}

.solution-container {
  height: 400px;
  overflow-y: auto;
}

.textarea-a {
  min-width: 30rem;
  min-height: 6rem;
}

.field {
  margin-bottom: 1.5em;
}

.tab-view {
  display: block;
}
.save-button {
  width: 10rem;
}
</style>