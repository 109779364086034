<script>
import LatexData from "@/components/LatexData.vue";
import { units } from "@/assets/materials";
import axios from "axios";
export default {
  name: "UpdateMaterial",
  components: {LatexData},
  created() {
    this.getSubUnits();
  },
  data() {
    return {
      text: '',
      rawText: '',
      subunitId: '',
      subCategory: '',
      name: '',
      category:'',
      title:'',
      subunits: [],
      selectedSubunit: ''
    }
  },
  methods: {
    getSubUnits(){
      units.forEach(item => {
        item.subunits.forEach(subunit => {
          this.subunits.push( {
            id: subunit.id,
            name: subunit.subCategory
          })
        });
      })
    },
    async handleSubUnitChange(event){
      const unitName = event.value.name;
      const response = await axios.get('/subunit/subCategory/', {
        params: {
          subCategory: unitName
        }
      });
      if(response.data){
        const subunit = response.data;
        this.rawText = subunit.text;
        this.category = subunit.category;
        this.subCategory = subunit.subCategory;
        this.title = subunit.title;
        this.name = subunit.name;
        this.renderText(subunit.text);
      }
    },
    renderText(event) {
      this.text = event;
      this.$refs.textLatex.processEqu(event);
    },
    async onSubmit() {
      this.rawtext
      const response = await axios.post('/subunit/update', {
        category: this.category,
        subCategory: this.subCategory,
        text: this.rawText,
        title: this.title,
        name: this.name
      });
      if (response.status === 200) {
        this.$toast.add({severity: 'success', sumary: 'Sukces', detail: 'Sukces', group: 'tc', life: 2000});
        this.resetForm();
      }
    },
  }
}
</script>

<template>
  <div class="mb-4 mt-2">
    <Dropdown v-model="selectedSubunit" :options="subunits" optionLabel="name" placeholder="Subunit" @change="handleSubUnitChange" class="input"/>
  </div>
  <div v-if="rawText !== ''">
    <Button class="button" type="submit" label="Zapisz" icon="pi pi-check" @click="onSubmit"/>
    <div class="field mb-5 ">
      <span class="p-float-label">
        <InputText id="subCategory" v-model="subCategory" type="text" class="input"/>
        <label for="subCategory">SubCategory</label>
      </span>
    </div>
    <div class="field mb-5">
      <span class="p-float-label">
        <InputText id="name" v-model="name" type="text" class="input"/>
        <label for="name">Name</label>
      </span>
    </div>
    <div class="field mb-5">
      <span class="p-float-label">
        <InputText id="title" v-model="title" type="text" class="input"/>
        <label for="title">Title</label>
      </span>
    </div>
    <div class="grid mt-5">
      <div class="col col-6">
        <div class="field">
              <span class="p-float-label">
                <Textarea class="textarea" v-model="rawText" @input="renderText(rawText)"></Textarea>
                 <label for="rawSolution">Tekst</label>
              </span>
        </div>
      </div>
      <div class="col col-6">
        <h4>Opis:</h4>
        <LatexData ref="textLatex" :equ="text"></LatexData>
      </div>
    </div>

  </div>
</template>

<style scoped>
.button {
  width: 20em;
  margin-bottom: 2em;
}
.input {
  width: 20em;
}
.textarea {
  min-width: 30rem;
  min-height: 20rem;
}
@media screen and (max-width: 640px) {
  .textarea {
    min-width: 100%;
  }
}
</style>