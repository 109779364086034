<script>
import axios from 'axios';
import Table from "@/components/Table.vue";
import TaskForm from "@/components/TaskForm.vue";

export default {
  name: "adminSQL" ,
  components: {TaskForm, Table},
  data() {
    return {
      taskLogColumns: [
        {
          field: 'id',
          header: 'Id'
        },
        {
          field: 'username',
          header: 'Username'
        },
        {
          field: 'email',
          header: 'Email'
        },
        {
          field: 'types',
          header: 'Types'
        },
        {
          field: 'taskId',
          header: 'Task Id'
        },
        {
          field: 'unit',
          header: 'Unit'
        },
        {
          field: 'createdDate',
          header: 'Created Date'
        },
        {
          field: 'units',
          header: 'Units',
        },
      ],
      freePlanLogColumns: [
        {
          field: 'id',
          header: 'Id'
        },
        {
          field: 'username',
          header: 'Username'
        },
        {
          field: 'email',
          header: 'Email'
        },
        {
          field: 'numberOfFreeTasks',
          header: 'Free Tasks left'
        },
        {
          field: 'createdDate',
          header: 'Created Date'
        },
      ],
      usersColumns: [
        {
          field: 'username',
          header: 'Username'
        },
        {
          field: 'email',
          header: 'Email'
        },
        {
          field: 'createdDate',
          header: 'Created Date'
        },
        {
          field: 'type',
          header: 'Type'
        },
        {
          field: 'customerId',
          header: 'Customer Id'
        },
        {
          field: 'isPremiumUser',
          header: 'Is Premium User'
        },
        {
          field: 'isEmailVerified',
          header: 'Email Verified'
        },
        {
          field: 'subExpirationDate',
          header: 'Sub Exp Date'
        },
        {
          field: 'freePlanActivated',
          header: 'fp Activated'
        },
        {
          field: 'freePlanExpired',
          header: 'fp Expired'
        }
      ],
      loginTokenColumns: [
        {
          field: 'id',
          header: 'Id'
        },
        {
          field: 'email',
          header: 'Email'
        },
        {
          field: 'createdDate',
          header: 'Created Date'
        },
        {
          field: 'expiredDate',
          header: 'Expired Date'
        },
        {
          field: 'isUsed',
          header: ' Is Used'
        },
        {
          field: 'device',
          header: 'Device'
        }
      ],
      userAnswersColumns: [
        {
          field: 'id',
          header: 'Id'
        },
        {
          field: 'username',
          header: 'Username'
        },
        {
          field: 'email',
          header: 'Email'
        },
        {
          field: 'questionId',
          header: 'Question Id'
        },
        {
          field: 'selectedAnswers',
          header: 'Selected Answers'
        },
        {
          field: 'isCorrect',
          header: 'Is Correct?'
        },
        {
          field: 'createdDate',
          header: 'Created Date'
        },
      ],
      queries: [
        {
          label: 'Task Log',
          value: 'taskLog'
        },
        {
          label: 'FreePlan Log',
          value: 'freePlanLog'
        },
        {
          label: 'Users',
          value: 'users'
        },
        {
          label: 'Logins',
          value: 'logins'
        },
        {
          label: 'User Answers',
          value: 'userAnswers'
        }
      ],
      query: '',
      user: {},
      showTable: false,
      records: [],
      showTaskLog: false,
      showFreePlanLog: false,
      columns: []
    }
  },
  methods: {
    async handleQueryChange(name){
      const token = this.getCookie('lz_token');
      const response = await axios.get('/tasks/getAdminQuery', {
          params: {
            token: token,
            query: name
          }
        });
      this.records = response.data;
      this.records.forEach(record => {
        record.createdDate = new Date(record.createdDate).toLocaleString('pl-PL');
        record.expiredDate = new Date(record.expiredDate).toLocaleString('pl-PL');
        if(record.subExpirationDate) {
          record.subExpirationDate= new Date(record.subExpirationDate).toLocaleString('pl-PL');
        }
      });
      this.showTable = this.records.length > 0;
      if(name === 'taskLog') {
        this.columns = this.taskLogColumns;
      } else if(name === 'freePlanLog') {
        this.columns = this.freePlanLogColumns;
      } else if(name === 'users') {
        this.columns = this.usersColumns;
      } else if(name === 'logins') {
        this.columns = this.loginTokenColumns;
      } else if(name === 'userAnswers') {
        this.columns = this.userAnswersColumns;
      }
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="table-body">
    <div class="mb-4 mt-2">
      <Dropdown v-model="query" :options="queries" optionLabel="label" placeholder="Query" @change="handleQueryChange(query.value)" class="input"/>
    </div>
    <template v-if="showTable">
      <DataTable :value="records" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
                 paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
                 paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                 currentPageReportTemplate="{first} - {last} z {totalRecords}">
        <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" style="max-width:200px"></Column>
      </DataTable>
    </template>
  </div>
</template>

<style scoped>

.table-body {
  width: 100%;
}
.input {
  width: 15em;
  margin-right: 2rem;
}
</style>