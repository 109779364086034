<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Funkcje",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) określa funkcje jako jednoznaczne przyporządkowanie za pomocą opisu słownego, tabeli, wykresu, wzoru (także
        różnymi wzorami na różnych przedziałach);</p>
      <p>2) oblicza wartość funkcji zadanej wzorem algebraicznym;</p>
      <p>3) odczytuje i interpretuje wartości funkcji określonych za pomocą tabel, wykresów, wzorów itp., również w
        sytuacjach wielokrotnego użycia tego samego źródła informacji lub kilku źródeł jednocześnie;</p>
      <p>4) odczytuje z wykresu funkcji: dziedzinę, zbiór wartości, miejsca zerowe, przedziały monotoniczności,
        przedziały, w których funkcja przyjmuje wartości większe (nie mniejsze) lub mniejsze (nie większe) od danej
        liczby, największe i najmniejsze wartości funkcji (o ile istnieją) w danym przedziale domkniętym oraz argumenty,
        dla których wartości największe i najmniejsze są przez funkcję przyjmowane;</p>
      <p>5) interpretuje współczynniki występujące we wzorze funkcji liniowej;</p>
      <p>6) wyznacza wzór funkcji liniowej na podstawie informacji o jej wykresie lub o jej własnościach;</p>
      <p>7) szkicuje wykres funkcji kwadratowej zadanej wzorem;</p>
      <p>8) interpretuje współczynniki występujące we wzorze funkcji kwadratowej w postaci ogólnej, kanonicznej i iloczynowej (jeśli istnieje);</p>
      <p>9) wyznacza wzór funkcji kwadratowej na podstawie informacji o tej funkcji lub o jej wykresie;</p>
      <p>10) wyznacza największą i najmniejszą wartość funkcji kwadratowej w przedziale domkniętym;</p>
      <p>11) wykorzystuje własności funkcji liniowej i kwadratowej do interpretacji zagadnień geometrycznych, fizycznych itp., także osadzonych w kontekście praktycznym;</p>
      <LatexData class="small-font" equ="12) na podstawie wykresu funkcji$\ y=f(x)$ szkicuje wykresy funkcji$\ y=f(x−a),\; y=f(x)+b$"></LatexData>
      <LatexData class="small-font" equ="13) posługuje się funkcją$\ f(x)=ax$, w tym jej wykresem, do opisu i interpretacji zagadnień związanych z wielkościami odwrotnie proporcjonalnymi, również w zastosowaniach praktycznych;"></LatexData>
      <p>14) posługuje się funkcjami wykładniczą i logarytmiczną, w tym ich wykresami, do opisu i interpretacji zagadnień związanych z zastosowaniami praktycznymi.</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <LatexData class="small-font" equ="1) na podstawie wykresu funkcji$\ y=f(x)$ szkicuje wykresy funkcji$\ y=−f(x),\;y=f(−x)$"></LatexData>
      <p>2) posługuje się złożeniami funkcji;</p>
      <LatexData class="small-font" equ="3) dowodzi monotoniczności funkcji zadanej wzorem, jak w przykładzie: wykaż, że funkcja $f(x)=\dfrac{x−1}{x+2}$ jest monotoniczna w przedziale$\ (−∞,−2)$."></LatexData>
    </div>
  </div>
</template>

<style scoped>

</style>