<script>
import CommentLatex from "@/components/CommentLatex.vue";
import axios from "axios";

export default {
  name: "Comment",
  components: {CommentLatex},
  emits:['delete-comment'],
  props: {
    comment: Object,
    usersComment: Boolean
  },
  data() {
    return {
      name: '',
      userType: '',
      options: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      },
    }
  },
  created() {
    this.formatComment();
  },
  methods: {
    formatComment() {
      this.name = this.comment.username.substring(0, 1).toUpperCase();
      this.userType = this.comment.type;
      this.comment.createdDate = new Date(this.comment.createdDate).toLocaleString('pl-PL', this.options);
      this.comment.message =   this.formatUsername(this.comment.message);

    },
    formatUsername(comment) {
      return comment.replace(/@(\w+)/g, '<strong>@$1</strong>');
    },
    async handleDeleteComment() {
      const response = await axios.post('/tasks/deleteComment', {
        comment: this.comment,
        customerId: this.comment.customerId
      });
      if(response.data) {
        this.$emit('delete-comment');
        this.showToast('success', 'Komentarz usunięty', '', 2000);
      } else {
        this.showToast('error', 'Błąd','Wystapił błąd podczas usuwania', 2000);
      }
    }
  }
}
</script>

<template>
  <div class="parent-container">
    <div class="circle">
      {{ name }}
    </div>
    <div class="container">
      <p class="header">{{ comment.username }}
        <span class="timestamp">
          {{ comment.createdDate }} -
        </span>
        <span v-if="userType === 'admin'" class="admin">
          Administrator
        </span>
        <span v-else class="student">
          Użytkownik
        </span>
      </p>
      <div class="message">
        <p v-html="comment.message"></p>
      </div>
    </div>

  </div>
  <div class="button-container">
    <Button v-if="usersComment" class="delete-button button-border" label="Usuń komentarz" severity="danger" @click="handleDeleteComment"></Button>
  </div>


</template>

<style scoped>
.container {
  border-radius: 6px;
  /*
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  */
  padding: 0 0.5rem 0 0.5rem;
  width: 100%;
  background-color: #f8f8f8;
  float: left;
}

.parent-container {
  display: flex;
  align-items: center;

}

.circle {
  min-width: 50px;
  min-height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 2rem;
  color: white;
  text-align: center;
  background: #8B5CF6;
  float: left;
  margin-right: 1rem;

}

.header {
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #4b5563;
}

.timestamp {
  color: gray;
  font-size: 0.8rem;
}

.admin {
  color: #8B5CF6;
  font-size: 0.8rem;
}

.student {
  color: gray;
  font-size: 0.8rem;
}

.message {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 100%;
}

.button-container {
  width: 100%;
  display: flex;
  margin-bottom: 1.5rem;
}

.delete-button {
  padding: 0.4rem;
  margin-left: auto;
  font-size: 0.8rem;
}
@media screen and (max-width: 640px) {
  .header {
    font-size: 0.8rem;
  }

  .message {
    font-size: 0.8rem;
  }
}
</style>