<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "GeometriaAnalityczna",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) rozpoznaje wzajemne położenie prostych na płaszczyźnie na podstawie ich równań, w tym znajduje wspólny punkt dwóch prostych, jeśli taki istnieje;</p>
      <p>2) posługuje się równaniem prostej na płaszczyźnie w postaci kierunkowej, w tym wyznacza równanie prostej o zadanych własnościach (takich jak na przykład przechodzenie przez dwa dane punkty, znany współczynnik kierunkowy, równoległość do innej prostej);</p>
      <p>3) oblicza odległość dwóch punktów w układzie współrzędnych;</p>
      <LatexData class="small-font" equ="4) posługuje się równaniem okręgu$\ (x−a)^2+(y−b)^2=r^2$"></LatexData>
      <p>5) wyznacza obrazy okręgów i wielokątów w symetriach osiowych względem osi układu współrzędnych, symetrii środkowej (o środku w początku układu współrzędnych).</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) znajduje punkty wspólne prostej i okręgu;</p>
      <p>2) znajduje punkty wspólne dwóch okręgów;</p>
      <p>3) zna pojęcie wektora i oblicza jego współrzędne oraz długość, dodaje wektory i mnoży wektor przez liczbę, oba te działania wykonuje zarówno analitycznie, jak i geometrycznie;</p>
      <p>4) wyznacza równanie prostej prostopadłej do zadanej prostej i prostej stycznej do zadanego okręgu.</p>
    </div>
  </div>
</template>

<style scoped>

</style>