<script>
import axios from "axios";

export default {
  name: "ConfirmEmail",
  created() {
    this.validToken();
  },
  methods: {
    async validToken() {
      const url = this.$route.query;
      const response = await axios.post('/users/validEmailConfirmationToken', {
        email: url.email,
        token: url.token
      });
      if (response.data.status === 200) {
        this.showToast('success', 'Twoje konto zostało potwierdzone', '', 2000);
        this.$router.push('/');
      } else {
        this.showToast('error', 'Błąd',response.data.message, 2000);
      }
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
</template>

<style scoped>

</style>