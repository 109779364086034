const units = [
    {
        id: 2,
        category: 'liczidzia',
        title: 'Liczby i działania',
        subunits: [
            {
                id: 21,
                category: 'liczidzia',
                subCategory: 'podstawy-potegowania',
                name: 'Podstawy potęgowania',
            },
            {
                id: 22,
                category: 'liczidzia',
                subCategory: 'wzory-na-potegach',
                name: 'Wzory na potęgach',
            },
            {
                id: 23,
                category: 'liczidzia',
                subCategory: 'wzory-skroconego-mnozenia',
                name: 'Wzory skróconego mnożenia',
            },
            {
                id: 24,
                category: 'liczidzia',
                subCategory: 'srednia-artytmetyczna-mediana',
                name: 'Średnia arytmetyczna i mediana',
            }
        ]
    },
    {
        id: 1,
        category: 'log',
        title: 'Logarytmy',
        subunits: [
            {
                id: 11,
                category: 'log',
                subCategory: 'podstawy-logarytmu',
                name: 'Podstawy logarytmu',
            },
            {
                id: 12,
                category: 'log',
                subCategory: 'dodawanie-odejmowanie-logarytmow',
                name: 'Dodawanie i odejmowanie logarytmów',
            },
            {
                id: 12,
                category: 'log',
                subCategory: 'wlasnosci-logarytmu',
                name: 'Podstawowe własności logarytmu',
            }
        ]
    },
    {
        id: 3,
        category: 'func',
        title: 'Funkcje',
        subunits: [
            {
                id: 31,
                category: 'func',
                subCategory: 'definicja-funkcji',
                name: 'Definicja funkcji',
            },
            {
                id: 32,
                category: 'func',
                subCategory: 'dziedzina-funkcji',
                name: 'Dziedzina funkcji',
            },
            {
                id: 32,
                category: 'func',
                subCategory: 'monotonicznosc-funkcji',
                name: 'Monotoniczność funkcji',
            },
            {
                id: 33,
                category: 'func',
                subCategory: 'funkcja-roznowartosciowa',
                name: 'Funkcja różnowartościowa',
            }
        ]
    },
    {
        id: 4,
        category: 'flin',
        title: 'Funkcja liniowa',
        subunits: [
            {
                id: 41,
                category: 'flin',
                subCategory: 'definicja-funkcji-liniowej',
                name: 'Definicja funkcji liniowej',
            }
        ]
    },
    {
        id: 5,
        category: 'fkw',
        title: 'Funkcja kwadratowa',
        subunits: [
            {
                id: 41,
                category: 'fkw',
                subCategory: 'definicja-funkcji-kwadratowej',
                name: 'Definicja funkcji kwadratowej',
            },
            {
                id: 42,
                category: 'fkw',
                subCategory: 'miejsca-zerowe-funkcji-kwadratowej',
                name: 'Miejsca zerowe funkcji kwadratowej',
            },
            {
                id: 43,
                category: 'fkw',
                subCategory: 'wzory-vietea',
                name: 'Wzory Viete\'a',
            }
        ]
    }

];
module.exports = {units};