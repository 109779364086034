<script>
import axios from "axios";
import CommentLatex from "@/components/CommentLatex.vue";
export default {
  name: "CreatComment",
  components: {CommentLatex},
  emits:['create-comment'],
  props: {
    taskId: String,
    customerId: String
  },
  data() {
    return {
      rawMessage: '',
      message: ''
    }
  },
  methods: {
    renderMessage(event) {
      this.solution = event;
      this.$refs.latexComment.processEqu(event);
    },
    async handleButtonClick() {
      if(this.rawMessage.length < 256) {
        const response = await axios.post('/tasks/createComment', {
          customerId: this.customerId,
          taskId: this.taskId,
          message: this.rawMessage
        });
        if(response.data) {
          this.$emit('create-comment', {message: this.rawMessage});
          this.showToast('success', 'Komentarz dodany', '', 2000);
        }
        this.rawMessage = '';
      } else {
        this.showToast('error', 'Błąd', 'Limit znaków osiagniety', 2000);
      }
    },
    addEqu() {
      this.rawMessage += '$\\ $';
    },
    handleSqrt() {
      this.rawMessage += '\\sqrt{}';
    },
    handlePower() {
      this.rawMessage += '{}^';
    },
    handleFrac() {
      this.rawMessage += '\\frac{}{}';
    },
    showToast(severity, summary, detail, life) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tc',
        life: life
      });
    }
  }

}
</script>

<template>
  <Toast position="top-center" group="tc"/>
<!--  <div class="equ-container">
    <Button class="main-equ-button" label="dodaj równanie" @click="addEqu"> </Button>
    <Button class="equ-button" label="pierwiastek" @click="handleSqrt"></Button>
    <Button class="equ-button" label="potęga" @click="handlePower"></Button>
    <Button class="equ-button" label="ułamek" @click="handleFrac"></Button>
  </div>-->
  <div class="field">
     <span class="p-float-label">
        <Textarea class="textarea" v-model="rawMessage" @input="renderMessage(rawMessage)"></Textarea>
        <label for="rawMessage">Twoja wiadomość (max.255 znaków)</label>
     </span>
<!--    <div class="mt-1 comment-preview" v-if="rawMessage.length > 0">
      Podgląd Twojego komentarza:
      <CommentLatex ref="latexComment" :equ="rawMessage" class="small-font"></CommentLatex>
    </div>-->
<!--    <div>
      <Accordion value="0" class="mb-2">
        <AccordionTab header="Przykłady">
          <p>1. $\ ax^2 + bx+c$</p>
          <CommentLatex equ="$\ ax^2 + bx+c$" class="small-font"></CommentLatex>
          <p>2. $\ \sqrt[n]{a}$</p>
          <CommentLatex equ="$\ \sqrt[n]{a}$" class="small-font"></CommentLatex>
          <p>3. $\ \frac{a}{b}$</p>
          <CommentLatex equ="$\ \frac{a}{b}$" class="small-font"></CommentLatex>
          <p>4. $\ \log_ab = c$</p>
          <CommentLatex equ="$\ \log_ab = c$" class="small-font"></CommentLatex>
          <p>5. $\ \infty, \geq, \leq, \neq$</p>
          <CommentLatex equ="$\ \infty, \geq, \leq, \neq$$" class="small-font"></CommentLatex>
        </AccordionTab>
      </Accordion>
    </div>-->
    <Button class="button button-border" label="Skomentuj" @click="handleButtonClick()" icon="pi pi-send"></Button>
  </div>
</template>

<style scoped>
.textarea {
 width: 100%;
  min-height: 8rem;
}
.button {
  width: 12rem;
}
.comment-preview {
  color: #4b5563;
}
.equ-container {
    width: 100%;
    display: flex;
    margin-bottom: 0.4rem;
}
.main-equ-button {
  padding: 0.4rem;
  margin-left: auto;
  font-size: 0.8rem;

}
.equ-button {
  margin-left: 0.2rem;
  padding: 0.4rem;
  font-size: 0.6rem;
}
.svg {

}
</style>