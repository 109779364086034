<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Trygonometria",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <LatexData class="small-font" equ="1) wykorzystuje definicje funkcji: sinus, cosinus i tangens dla kątów od $0^\circ$ do $180^\circ$, w szczególności wyznacza wartości funkcji trygonometrycznych dla kątów $30^\circ,45^\circ,60^\circ$;"></LatexData>
      <LatexData class="small-font" equ="2) korzysta z wzorów $\sin^2\alpha+\cos^2\alpha=1, tgα=\dfrac{\sinα}{\cosα}$"></LatexData>
      <LatexData class="small-font" equ="3) stosuje twierdzenie cosinusów oraz wzór na pole trójkąta $P=12\cdot a\cdot b \cdot \sin\gamma$"></LatexData>
      <p>4) oblicza kąty trójkąta prostokątnego i długości jego boków przy odpowiednich danych (rozwiązuje trójkąty prostokątne, w tym z wykorzystaniem funkcji trygonometrycznych).</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) stosuje miarę łukową, zamienia stopnie na radiany i odwrotnie;</p>
      <p>2) posługuje się wykresami funkcji trygonometrycznych: sinus, cosinus, tangens;</p>
      <p>3) wykorzystuje okresowość funkcji trygonometrycznych;</p>
      <p>4) stosuje wzory redukcyjne dla funkcji trygonometrycznych;</p>
      <p>5) korzysta z wzorów na sinus, cosinus i tangens sumy i różnicy kątów, a także na funkcje trygonometryczne kątów podwojonych;</p>
      <p>6) rozwiązuje równania trygonometryczne;</p>
      <p>7) stosuje twierdzenie sinusów;</p>
      <p>8) oblicza kąty trójkąta i długości jego boków przy odpowiednich danych (rozwiązuje trójkąty).</p>
    </div>
  </div>
</template>

<style scoped>

</style>