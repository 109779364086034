<script>
import UserData from "@/components/UserData.vue";
import FavoriteTasks from "@/components/FavoriteTasks.vue";
import Subscriptions from "@/components/Subscriptions.vue";
import {SemipolarSpinner} from "epic-spinners";
import {tabs} from '@/assets/utils.js';

export default {
  name: "Profile",
  components: {UserData, Subscriptions, FavoriteTasks, SemipolarSpinner},
  created() {
    this.getToken();
    this.showSpinner = false;
  },
  data() {
    return {
      token: '',
      showSpinner: false,
      tabs: tabs,
      selectedTab: ''
    }
  },
  methods: {
    getToken() {
      this.token = this.getCookie('lz_token');
      if(!this.token) {
        this.$router.push('/');
      }
    },
    handleAddressChanged() {
      this.$refs.subs.getActiveSubscription();
    },
    handleShowSpinner(value) {
      this.showSpinner = value;
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Profil</h2>
      <div v-if="showSpinner" class="custom-spinner">
        <div class="spinner">
          <semipolar-spinner
              :animation-duration="2000"
              :size="80"
              color="#8B5CF6"
          />
        </div>
      </div>
      <TabView class="tab-view">
        <TabPanel header="Zarządaj subskrypcją">
          <Subscriptions ref="subs" :token="token" @show-spinner="handleShowSpinner"></Subscriptions>
        </TabPanel>
        <TabPanel header="Polubione zadania">
          <FavoriteTasks :token="token"></FavoriteTasks>
        </TabPanel>
      </TabView>
      <div class="mobile-view">
        <Dropdown v-model="selectedTab" :options="tabs" optionLabel="label" placeholder="-Wybierz-" class="input"/>
        <div v-if="selectedTab.name === 'sub' || selectedTab === ''" class="mobile-container">
          <h3 class="header">Zarządzaj subksrypcją</h3>
          <hr/>
          <div class="component-container">
            <Subscriptions ref="subs" :token="token" @show-spinner="handleShowSpinner"></Subscriptions>
          </div>
        </div>
        <div v-if="selectedTab.name === 'favoriteTasks'" class="mobile-container">
          <h3 class="header">Polubione zadania</h3>
          <hr/>
          <div class="component-container">
            <FavoriteTasks :token="token"></FavoriteTasks>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-panel {
  margin-top: 1rem;
  justify-content: center;
  text-align: left;
  width: 100%;
}
.card-body {
  background-color: white;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 80em;
}
.tab-view {
  display: block;
}
.mobile-view {
  display:none;
}
.input {
  width: 100%;
}
.mobile-container {
  margin-top: 2rem;
}
.component-container {
   margin-top: 3rem;
}
.custom-spinner {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
}
@media screen and (max-width: 640px) {
  .card-panel {
    width: 100%;
  }
  .card-body {
    width: 100%;
    padding: 1rem;
  }
  .tab-view {
    display: none;
  }
  .mobile-view {
    display:block;
  }
}
</style>