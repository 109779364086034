<script>
import {examYears} from '@/assets/examSheetsUtils.js';
import axios from "axios";

export default {
  name: "ExamSheets",
  data() {
    return {
      examYears: examYears
    }
  },
  methods: {
 /*   async getExamYears() {
      let examList = [];
      const response = await axios.get('/exams/getExamYears');
      this.examYears = response.data;
      console.log('## exam year ', this.examYears);
      const examsResponse = await axios.get('/exams/getExams');
      console.log('## exams  ', examsResponse.data);
      examsResponse.data.forEach(exam => {
        if(examList.includes(exam.year)) {
         let tempExam = examsList.find(exam => exam.id === exam.year);
          tempExam.exams.push(exam);
        } else {
          examList.push({id: exam.year, name: exam.urlName});
        }
      })

    },*/
    handleYearSelect(event) {
      this.$router.push({
        name: 'arkusze',
        params: {
          name: event.urlName
        }
      });
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Arkusze maturalne</h2>
      <div class="container">
        <div v-for="year in examYears" class="exam-year">
          <h2 class="year-header">{{ year.name }}</h2>
          <div class="grid">
            <div class="type-header col-12">
              <h4 class="default-color">Poziom podstawowy:</h4>
            </div>
            <div v-for="month in year.monthsPP" class="md:col-6 col-12">
              <Button class="button button-border" :label="month.name" @click="handleYearSelect(month)"></Button>
            </div>
          </div>
          <div class="grid">
            <div class="type-header col-12">
              <h4 class="default-color">Poziom roszerzony:</h4>
            </div>
            <div v-for="month in year.monthsPR" class="md:col-6 col-12">
              <Button class="button button-border" :label="month.name" @click="handleYearSelect(month)"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.year-header {
  color: #8B5CF6;
  font-weight: bold;
}

.button {
  width: 15rem;
}

.type-header > h4 {
  margin: 0;
}

.exam-year {
  border: 2px solid #4b5563;
  padding: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 640px) {
  .col {
    text-align: center;
  }
  .button {
    width: 100%;
  }
}
</style>