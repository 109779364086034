<script>
import LiczbyR from "@/components/Requirements2025/LiczbyR.vue";
import WyrazeniaAlgb from "@/components/Requirements2025/WyrazeniaAlgb.vue";
import RownNrow from "@/components/Requirements2025/RownNrow.vue";
import UklRow from "@/components/Requirements2025/UklRow.vue";
import Funkcje from "@/components/Requirements2025/Funkcje.vue";
import Ciagi from "@/components/Requirements2025/Ciagi.vue";
import Trygonometria from "@/components/Requirements2025/Trygonometria.vue";
import Planimetria from "@/components/Requirements2025/Planimetria.vue";
import GeometriaAnalityczna from "@/components/Requirements2025/GeometriaAnalityczna.vue";
import Sterometria from "@/components/Requirements2025/Sterometria.vue";
import Kombinatoryka from "@/components/Requirements2025/Kombinatoryka.vue";
import RachunekPrawd from "@/components/Requirements2025/RachunekPrawd.vue";
import RachunekRoz from "@/components/Requirements2025/RachunekRoz.vue";

export default {
  name: "Requirements",
  components: {
    LiczbyR,
    WyrazeniaAlgb,
    RownNrow,
    UklRow,
    Funkcje,
    Ciagi,
    Trygonometria,
    Planimetria,
    GeometriaAnalityczna,
    Sterometria,
    Kombinatoryka,
    RachunekPrawd,
    RachunekRoz
  },
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <h2>Wymagania szczegółowe na maturze 2025</h2>
        <Accordion :value="['0']" multiple>
          <AccordionTab value="0" header="1. Liczby rzeczywiste">
            <LiczbyR></LiczbyR>
          </AccordionTab>
          <AccordionTab value="1" header="2. Wyrażenia algebraiczne">
            <WyrazeniaAlgb></WyrazeniaAlgb>
          </AccordionTab>
          <AccordionTab value="2" header="3. Równania i nierówności">
            <RownNrow></RownNrow>
          </AccordionTab>
          <AccordionTab value="3" header="4. Układy równań">
            <UklRow></UklRow>
          </AccordionTab>
          <AccordionTab value="4" header="5. Funkcje">
            <Funkcje></Funkcje>
          </AccordionTab>
          <AccordionTab value="5" header="6. Ciągi">
            <Ciagi></Ciagi>
          </AccordionTab>
          <AccordionTab value="6" header="7. Trygonometria">
            <Trygonometria></Trygonometria>
          </AccordionTab>
          <AccordionTab value="7" header="8. Planimetria">
            <Planimetria></Planimetria>
          </AccordionTab>
          <AccordionTab value="8" header="9. Geometria analityczna na płaszczyźnie kartezjańskiej">
            <GeometriaAnalityczna></GeometriaAnalityczna>
          </AccordionTab>
          <AccordionTab value="9" header="10. Stereometria">
            <Sterometria></Sterometria>
          </AccordionTab>
          <AccordionTab value="10" header="11. Kombinatoryka">
            <Kombinatoryka></Kombinatoryka>
          </AccordionTab>
          <AccordionTab value="11" header="12. Rachunek prawdopodobieństwa i statystyka">
            <RachunekPrawd></RachunekPrawd>
          </AccordionTab>
          <AccordionTab value="12" header="13. Optymalizacja i rachunek różniczkowy">
            <RachunekRoz></RachunekRoz>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-center {
  justify-content: center;
  align-items: center;
}

.container {
  padding: 1rem;
}

.custom-header {
  color: #8b5cf6;
  margin-bottom: 2rem;
}

@media screen and (max-width: 640px) {
  .container {
    padding: 0
  }
}
</style>