<script>
import axios from "axios";

export default {
  name: "Ranking",
  created() {
    this.getMonth();
    this.getStudentRanking();
    this.getUser();
  },
  data() {
    return {
      records: [],
      monthName: '',
    }
  },
  methods: {
    getMonth() {
      const date = new Date();
      this.monthName = date.toLocaleString('pl-PL', { month: 'long' });
    },
    async getStudentRanking() {
      const response = await axios.get('/tasks/getRanking');
      this.records = response.data.currentMonthRanking;
    },
    async getUser() {
      console.log('## in get user ');
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
      }
    },
    getRowClass(data) {
      //return data.username === this.user.username ? 'highlight-row' : '';
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Ranking miesiąca {{monthName}}</h2>
      <DataTable :value="records"
                 tableStyle="width: 100%"
                 columnResizeMode="fit"
                 size="small"
                 paginator
                 :rows="25"
                 :rowClass="getRowClass">
        <Column field="username" header="Nazwa użytkownika">
          <template #body="{ data, index }">
            <div class="flex items-center gap-2">
             <i v-if="index === 0" class="pi pi-crown" style="color: gold"></i>
             <i v-if="index === 1" class="pi pi-crown" style="color: silver"></i>
             <i v-if="index === 2" class="pi pi-crown" style="color: #cd7f32"></i>
              <span class="username">{{ data.username }}</span>
            </div>
          </template>
        </Column>
        <Column field="totalPoints" header="Punkty"></Column>
      </DataTable>
    </div>
  </div>
</template>

<style scoped>
.pi-crown {
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.2rem;
}
.username {
  font-size: 1rem;
}
.highlight-row {
  background-color: #8B5CF6;
}
</style>