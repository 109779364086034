<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "LiczbyR",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) wykonuje działania (dodawanie, odejmowanie, mnożenie, dzielenie, potęgowanie, pierwiastkowanie,
        logarytmowanie) w zbiorze liczb rzeczywistych;</p>
      <p>2) przeprowadza proste dowody dotyczące podzielności liczb całkowitych i reszt z dzielenia,
        np.:</p>
      <ul>
        <li>
          <LatexData class="small-font"
                     equ="a) dowód podzielności przez$\ 24$ iloczynu czterech kolejnych liczb naturalnych,"></LatexData>
        </li>
        <li>
          <LatexData class="small-font"
                     equ="b) dowód własności: jeśli liczba przy dzieleniu przez$\ 4$ daje resztę$\ 3$, to nie jest kwadratem liczby całkowitej;"></LatexData>
        </li>
      </ul>
      <p>3) stosuje własności pierwiastków dowolnego stopnia, w tym pierwiastków stopnia nieparzystego z
        liczb ujemnych;</p>
      <p>4) stosuje związek pierwiastkowania z potęgowaniem oraz prawa działań na potęgach i
        pierwiastkach;</p>
      <LatexData class="small-font" equ="5) stosuje własności monotoniczności potęgowania, w szczególności własności: jeśli$\ x<y$ oraz$\ a>1$,
                   to$\ ax<ay$, zaś gdy$\ x<y$ i$\ 0<a<1$, to$\ ax>ay$;"></LatexData>
      <p>6) posługuje się pojęciem przedziału liczbowego, zaznacza przedziały na osi liczbowej;</p>
      <LatexData class="small-font" equ="7) stosuje interpretację geometryczną i algebraiczną wartości bezwzględnej, rozwiązuje równania typu:$\ |x+4|=5$;"></LatexData>
      <p>8) wykorzystuje własności potęgowania i pierwiastkowania w sytuacjach praktycznych, w tym do obliczania procentów składanych, zysków z lokat i kosztów kredytów;</p>
      <p>9) stosuje związek logarytmowania z potęgowaniem, posługuje się wzorami na logarytm iloczynu, logarytm ilorazu i logarytm potęgi.</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      <p>Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto stosuje wzór na zamianę podstawy logarytmu.</p>
    </div>
  </div>
</template>

<style scoped>

</style>