<script>
import {examYears, examNames} from '@/assets/examSheetsUtils.js';
import axios from "axios";
import TaskQuestionView from "@/components/TaskQuestionView.vue";

export default {
  name: "SingleExamSheet",
  components: {TaskQuestionView},
  data() {
    return {
      examYears: examYears,
      examNames: examNames,
      selectedExam: {},
      selectedExamUrl: '',
      tasks: [],
      hideButtons: false,
      user: {}
    }
  },
  created() {
    this.getTasks();
    this.checkUser();
  },
  methods: {
    async checkUser() {
      const token = this.getCookie('lz_token');
      if (token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: token
          }
        });
        this.user = response.data;
      }
      this.hideButtons =
          !this.user.id
          || this.user.isPremiumUser === 0
          || this.user.freePlanActivated === 1;
    },
    async getTasks() {
      this.selectedExamUrl = this.$route.params.name;
      this.selectedExam = this.examNames.find(exam => exam.urlName === this.selectedExamUrl);
      const response = await axios.get('/tasks/recordsByYearMonthType', {
        params: {
          year: this.selectedExam.year,
          month: this.selectedExam.month,
          type: this.selectedExam.type
        }
      });
      if (response.data) {
        this.tasks = response.data;
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
    showTask(event) {
      this.$router.push({
        name: 'zadanie',
        params: {
          name: event.id
        }
      });
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <div class="container">
        <Button class="button" text label="Powrót" @click="handleBack"></Button>
        <h2 class="exam-header text-center mb-4">{{ selectedExam.header }}</h2>
        <div class="task-container">
          <div v-for="task in tasks" :key="task.id" class="task">
            <h2 class="text-center task-header">Zadanie {{ task.taskNumber }}.</h2>
            <div class="task-image text-center">
              <img :src="task.qUrl" :alt="`Zadanie: ${task.id}`" class="image-q"/>
            </div>
            <div class="mt-5" v-if="hideButtons === false">
               <TaskQuestionView :task-id="task.id"
                                :is-multiple-choice="task.isMultipleChoice"
                                :customer-id="user.customerId"
                                :is-proof-task="task.aUrl.includes('Patrz')">
              </TaskQuestionView>
              <div class="text-center mt-4">
                <Button class="task-button button-border" label="Pokaż rozwiązanie" @click="showTask(task)" icon="pi pi-check"></Button>
              </div>
            </div>
            <div v-else>
              <div class="text-center mt-5">
                <h3>Rozwiązania zadań są dostepne w pakiecie płatnym.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button {
  padding-left: 0;
  font-weight: 700;
}
.task-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.task {
  padding-bottom: 1rem;
}

.task-header {
  background-color: #d5b8ea;
  padding: 0.2rem 0;
}

.task-button {
  width: 20rem;
}

@media screen and (max-width: 640px) {
  .task-container {
    width:100%;
  }
  .task-button {
    width:100%;
  }

  .button {
    font-size: 1rem;
  }

  h2 {
    font-size: 1rem;
  }

  .image-q {
    max-width: 100%;
  }

  .task-header {
    padding: 0.1rem 0;
  }
}
</style>