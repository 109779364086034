<script>
import LatexData from "@/components/LatexData.vue";

export default {
  name: "Planimetria",
  components: {LatexData}
}
</script>

<template>
  <div class="grid">
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres podstawowy:</h4>
      Uczeń:
      <p>1) wyznacza promienie i średnice okręgów, długości cięciw okręgów oraz odcinków stycznych, w tym z wykorzystaniem twierdzenia Pitagorasa;</p>
      <p>2) rozpoznaje trójkąty ostrokątne, prostokątne i rozwartokątne przy danych długościach boków (m.in. stosuje twierdzenie odwrotne do twierdzenia Pitagorasa i twierdzenie cosinusów); stosuje twierdzenie: w trójkącie naprzeciw większego kąta wewnętrznego leży dłuższy bok;</p>
      <p>3) rozpoznaje wielokąty foremne i korzysta z ich podstawowych własności;</p>
      <p>4) korzysta z własności kątów i przekątnych w prostokątach, równoległobokach, rombach i trapezach;</p>
      <p>5) stosuje własności kątów wpisanych i środkowych;</p>
      <p>6) stosuje wzory na pole wycinka koła i długość łuku okręgu;</p>
      <p>7) stosuje twierdzenie Talesa;</p>
      <p>8) korzysta z cech podobieństwa trójkątów;</p>
      <p>9) wykorzystuje zależności między obwodami oraz między polami figur podobnych;</p>
      <p>10) wskazuje podstawowe punkty szczególne w trójkącie: środek okręgu wpisanego w trójkąt, środek okręgu opisanego na trójkącie, ortocentrum, środek ciężkości oraz korzysta z ich własności;</p>
      <p>11) przeprowadza dowody geometryczne;</p>
      <p>12) stosuje funkcje trygonometryczne do wyznaczania długości odcinków w figurach płaskich oraz obliczania pól figur;</p>
    </div>
    <div class="field md:col-6 sm:col-12">
      <h4>Zakres rozszerzony:</h4>
      Uczeń spełnia wymagania określone dla zakresu podstawowego, a ponadto:
      <p>1) stosuje własności czworokątów wpisanych w okrąg i opisanych na okręgu.</p>
      <p>2) stosuje twierdzenie odwrotne do twierdzenia Talesa;</p>
    </div>
  </div>
</template>

<style scoped>

</style>