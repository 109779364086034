<script>
import {years, units, labelDetails, months} from '@/assets/utils.js';
import axios from "axios";
import LatexData from "@/components/LatexData.vue";
import TaskQuestion from "@/components/TaskQuestion.vue";

export default {
  name: 'TaskForm',
  components: {TaskQuestion, LatexData},
  watch: {
    taskNumber() {
      this.refreshTaskId();
    }
  },
  data() {
    return {
      task: {},
      month: '',
      year: '',
      type: '',
      unit: '',
      rawSolution: '',
      solution: '',
      rawAnswer: '',
      answer: '',
      taskNumber: '',
      taskId: '',
      showSolution: false,
      showSolutionImg: false,
      questionBlob: '',
      isMultipleChoice: 0,
      types: [
        {
          name: 'p',
          code: 'podstawowy'
        },
        {
          name: 'r',
          code: 'rozszerzony'
        }
      ],
      months: months,
      years: years,
      units: units,
      labelDetails: labelDetails,
      showSolutionAsJson: false,
      solutionAsArray: [],
      qUrl: ''
    }
  },
  methods: {
    async onSubmit() {
      this.refreshTaskId();
      let type = this.type ? this.type.name : '';
      let month = this.month.name ? this.month.name : '';
      let year = this.year.name ? this.year.name : '';
      let labelDetails = this.labelDetails.get(month);
      let label = `CKE, ${labelDetails}-poziom ${this.type.code}, ${this.month.code} ${this.year.code}`;
      let unit = this.unit.name ? this.unit.name : '';
      //let qUrl = 'https://storage.googleapis.com/losujzadanie/matury/'+ id + '/q.png';
      let qUrl = `https://storage.googleapis.com/losujzadanie/matury/${year}/${month}/${type}/${this.taskId}/q.png`;
      let solutionUrl = this.showSolutionImg ? `https://storage.googleapis.com/losujzadanie/matury/${year}/${month}/${type}/${this.taskId}/img.png` : '';
      if (!type && !month && !year && !unit) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Uzupełnij pola', group: 'tc', life: 2000});
        return;
      }
      this.task = {
        id: this.taskId,
        unit: unit,
        type: type,
        label: label,
        isFromExam: 1,
        month: month,
        year: year,
        taskNumber: this.taskNumber,
        showSolution: true,
        showSolutionImg: this.showSolutionImg,
        aUrl: this.answer,
        sUrl: this.solution,
        qUrl: qUrl,
        imgUrl: solutionUrl,
        isMultipleChoice: this.isMultipleChoice ? 1 : 0,
        createdDate: new Date()
      }
      const token = this.getCookie('lz_token');
      const res = await axios.post('/tasks/create', {
        task: this.task,
        token: token
      });
      if (res.status === 200) {
        this.$toast.add({severity: 'success', summary: 'Zadanie utworzone', detail: '', group: 'tc', life: 2000});
        this.resetForm();
      } else {
        this.$toast.add({severity: 'error', summary: 'Error', detail: res, group: 'tc', life: 2000});
      }
    },
    renderSolution(event) {
      this.solution = event;
      this.$refs.solutionLatex.processEqu(event);
    },
    renderAnswer(event) {
      this.answer = event;
      this.$refs.answerLatex.processEqu(event);
    },
    refreshTaskId() {
      let type = this.type ? this.type.name : '';
      let month = this.month.name ? this.month.name : '';
      let year = this.year.name ? this.year.name : '';
      let taskNumber = this.taskNumber < 10 ? '0' + this.taskNumber : this.taskNumber;
      if(type && month && year && taskNumber) {
        this.taskId = year + month + taskNumber + type;
        this.qUrl = `https://storage.googleapis.com/losujzadanie/matury/${year}/${month}/${type}/${this.taskId}/q.png`;
      }
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    resetForm() {
      this.unit = '';
      this.showSolutionImg = false;
      this.solution = '';

    },
    handleTabChange(event) {
      const activeTabIndex = event.index;
      if (activeTabIndex === 1 && this.$refs.taskQuestion.questions.length === 0) {
        this.$refs.taskQuestion.getQuestions();
      }
    }
    /* onUpload(event) {
      console.log('## event ', event);
       let file = event.files[0];
       let blob = file.slice(0, file.size, "image/png");
       this.qBlob = new File([blob], 'q.png', {type: "image/png"});
 /!*      this.qBlob =  new FormData();
       this.qBlob.append('imageFile', newFile);
       console.log('## this.qBlob ', this.qBlob);*!/
     },
     async upload() {
       const fd = new FormData();
       fd.append('qFile', this.qBlob);
       const res = await axios.post('/tasks/uploadImage', fd);
       console.log('## res ', res);
     }*/
  }
}
</script>

<template>
  <Toast position="top-center" group="tc"/>
  <TabView class="tab-view" @tab-change="handleTabChange">
    <TabPanel header="Zadanie">
      <div class="grid">
      <div class="col-6">
        <Button class="mb-5 save-button button-border" label="Zapisz" icon="pi pi-check" @click="onSubmit"/>
        <div class="field">
          <span class="p-float-label">
            <Dropdown v-model="unit" :options="units" optionLabel="label" placeholder="Dział" class="input"/>
            <label for="unit">Dział</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="taskNumber" v-model="taskNumber" type="text" class="input"/>
            <label for="taskNumber">Nr zadania</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <Dropdown v-model="type" :options="types" optionLabel="code" placeholder="Typ" class="input"/>
             <label for="type">Typ</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <Dropdown v-model="year" :options="years" optionLabel="code" placeholder="Rok" class="input"/>
            <label for="year">Rok</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label">
            <Dropdown v-model="month" :options="months" optionLabel="code" placeholder="Miesiac" class="input"
                      @change="refreshTaskId"/>
            <label for="month">Miesiąc</label>
          </span>
        </div>
      </div>
        <div class="col-6">
          <img v-if="qUrl !== ''" class="img" :src="qUrl" alt="Pytanie">
        </div>
      </div>
      <div class="solution-container">
        <LatexData ref="solutionLatex" :equ="solution"></LatexData>
      </div>
      <div class="field">
          <span class="p-float-label">
            <Textarea class="textarea" v-model="rawSolution" @input="renderSolution(rawSolution)"></Textarea>
            <label for="rawSolution">Rozwiązanie</label>
          </span>
      </div>
      <div>
        <LatexData ref="answerLatex" :equ="answer"></LatexData>
      </div>
      <div class="field">
              <span class="p-float-label">
                <Textarea class="textarea-a" v-model="rawAnswer" @input="renderAnswer(rawAnswer)"></Textarea>
                 <label for="rawAnswer">Odpowiedź</label>
              </span>
      </div>
      <div class="flex items-center">
        <Checkbox v-model="showSolutionImg" inputId="showSolutionImg" :binary="true"/>
        <label for="showSolutionImg" class="ml-2">Pokaż rysunek do rozwiazania?</label>
      </div>
      <div class="items-center mt-3">
        <Checkbox v-model="isMultipleChoice" inputId="isMultipleChoice" name="isMultipleChoice" :binary="true"/>
        <label for="isMultipleChoice" class="ml-2"> Wielokrotny wybór? </label>
      </div>
    </TabPanel>
    <TabPanel header="Odpowiedzi">
        <TaskQuestion ref="taskQuestion" :task-id="taskId" :q-url="qUrl" :answer="answer"></TaskQuestion>
    </TabPanel>
  </TabView>
</template>

<style scoped>
.field {
  margin-bottom: 1.5em;
}

.input {
  width: 20em;
}
.save-button{
  width: 10em;
}

.textarea {
  min-width: 40rem;
  min-height: 30rem;
}

.textarea-a {
  min-width: 40rem;
  min-height: 8rem;
}
.img {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .textarea {
    min-width: 100%;
  }

  .textarea-a {
    min-width: 100%;
  }
}
</style>