<script>
import LatexData from "@/components/LatexData.vue";
import axios from "axios";

export default {
  name: "TaskAnswerView",
  components: {LatexData},
  created() {
    this.getAnswers();
  },
  emits: ['answers-selected'],
  props: {
    questionId: String,
    taskId: String,
    isMultipleChoice: {
      type: Boolean,
      default: false
    },
    buttonLabel: String,
    isInteractionDisabled: Boolean,
    correctAnswers: {
      type: Array,
      default: () => []
    },
    incorrectAnswers: {
      type: Array,
      default: () => []
    },
    resetAnswers: Boolean
  },
  watch: {
    questionId() {
      this.getAnswers();
    }
  },
  data() {
    return {
      fetchedAnswers: [],
      selectedAnswers: [],
      showButtonLabel: true,
      answersLoaded: false,
    }
  },
  methods: {
    async getAnswers() {
      const response = await axios.get('/tasks/getAnswers', {
        params: {
          questionId: this.questionId,
          selectAllFields: false
        }
      });
      if (response.data.length > 0) {
        this.fetchedAnswers = response.data;
        this.answersLoaded = true;
      }
    },
    handleAnswerSelect(event) {
      if (this.isInteractionDisabled) {
        return;
      }
      const answerId = Number(event.id);
      if (!this.isMultipleChoice) {
        if (this.selectedAnswers.includes(answerId)) {
          this.selectedAnswers = [];
        } else {
          this.selectedAnswers = [answerId];
        }
      } else {
        const answerIndex = this.selectedAnswers.indexOf(answerId);
        if (answerIndex !== -1) {
          this.selectedAnswers.splice(answerIndex, 1);
        } else {
          this.selectedAnswers.push(answerId);
        }
      }
      this.$emit('answers-selected', {questionId: this.questionId, selectedAnswers: this.selectedAnswers});
    },
  }
}
</script>

<template>
  <div class="container">
    <template v-for="answer in fetchedAnswers" :key="answer.id">
      <div class="answer-container"
           :class="{
            selected: selectedAnswers.includes(Number(answer.id)) && resetAnswers === false,
            'correct-bg': correctAnswers.includes(Number(answer.id)),
            'incorrect-bg': incorrectAnswers.includes(Number(answer.id)),
            'disabled': isInteractionDisabled}"
           @click="handleAnswerSelect(answer)">
        <Button class="answer-label"
                :class="{
                  'button-selected': selectedAnswers.includes(Number(answer.id)) && resetAnswers === false,
                  'button-correct': correctAnswers.includes(Number(answer.id)),
                  'button-incorrect': incorrectAnswers.includes(Number(answer.id)),
                  'button-disabled': isInteractionDisabled}"
                :label="!correctAnswers.includes(answer.id) && !incorrectAnswers.includes(Number(answer.id)) ? answer.label : ''"
                :icon="correctAnswers.includes(answer.id) ? 'pi pi-check' : incorrectAnswers.includes(Number(answer.id)) ? 'pi pi-times' : ''">
        </Button>
        <LatexData :equ="answer.value" is-answer="true"></LatexData>
      </div>
    </template>
  </div>
</template>

<style scoped>
.answer-label {
  border: 2px solid #4b5563;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: white;
  font-size: 1.5rem;
  color: #4b5563;
}
.button-selected {
  background-color: #8B5CF6;
  color: white;
}
.button-correct {
  background-color: #22c55e;
  color: white;
  font-weight: bold;
}
.button-correct :deep(.p-button-icon) {
  font-size: 2rem;
  font-weight: bold;
}
.button-incorrect {
  background-color: #ef4444;
  color: white;
}
.button-incorrect :deep(.p-button-icon) {
  font-size: 2rem;
  font-weight: bold;
}
.button-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.answer-container {
  padding: 0.5rem;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.answer-container:hover {
  background-color: #dccffc;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.answer-container.disabled {
  pointer-events: none;
}
.answer-container.disabled:hover {
  background-color: inherit;
}
.selected {
  background-color: #cbb6fb;
}

.incorrect-bg {
  background-color: #fef1ee;
}
.correct-bg {
  background-color: #ebf7e6;
}

@media screen and (max-width: 640px) {
  .answer-label {
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 1rem;
  }
  .button-correct :deep(.p-button-icon) {
    font-size: 1rem;
    font-weight: bold;
  }
  .button-incorrect :deep(.p-button-icon) {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>